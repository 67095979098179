import { useState, useEffect } from "react";
import axios from "axios";
import dateFormat from "dateformat";
import { Card, Button, Modal, Form, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { GetApi } from "../../api/index";
import {
  MediaGroup,
  Media,
  MediaText,
  Image,
  Icon,
  CustomDropdownToggle,
  CustomDropdownMenu,
  LinkList,
  LinkListItem,
  Select,
} from "../../components";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import DataTable from "../../components/DataTable/DataTable";
// import { Icon, Select } from '../../components';
import { toInitials } from "../../utilities";
import UsersData from "../../store/users/UsersData";

function EventsList() {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // new state variable to track the loading status

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  let key = localStorage.getItem("user");

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    await axios
      .get(
        `${
          process.env.REACT_APP_SERVER || "https://api.chiyo.io/"
        }events/get/${key}`
      )
      .then(function (response) {
        let reverseArray = response.data.data.reverse();
        console.log("reverseArray", reverseArray);
        setData(reverseArray);
        setIsLoading(false); // set loading to false after the API call is completed
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false); // set loading to false if there is an error
      });
  };

  const userColumns = [
    {
      name: "Date & Time",
      selector: (row) => console.log(row?.chains),
      cell: (row) => (
        <>
          {" "}
          <span>
          {dateFormat(row?.createdAt, "dddd, mmmm dS, yyyy, h:MM:ss TT")}
          </span>
        </>
      ),
      sortable: true,
      // hide: "md",
    },
    {
      name: "Event Name",
      selector: (row) => console.log(row?.chains),
      cell: (row) => (
        <>
          {" "}
          <span>
            {row?.eventName
              ? row?.eventName
              : row?.chains[0]?.currency
              ? "Connected"
              : "Disconnected"}
          </span>
        </>
      ),
      sortable: true,
      // hide: "md",
    },
    {
      name: "walletAddress",
      grow: "2",
      // selector: (row) => row?.walletAddress,
      cell: (row) => (
        <>
          {row.walletAddress ? (
            <MediaText>
              <Link
                to={`/user-manage/user-profile/${row.walletAddress}`}
                className="title"
              >
                <b>
                  {row.walletAddress.slice(0, 5) +
                    "......." +
                    row.walletAddress.slice(-5)}
                </b>
              </Link>
              {/* <span className="small text">{row?.walletID}</span> */}
            </MediaText>
          ) : (
            "Null"
          )}
        </>
      ),
      sortable: true,
    },

    {
      name: "Blockchain",
      grow: 2,
      selector: (row) => console.log("-----", row?.chains),
      cell: (row) => (
        <>
          {" "}
          {row.chainId && (
            <Image
              className="image-upload-style"
              src={
                row?.chains[0]?.chainName == "Polygon "
                  ? "https://clabucket.s3.ca-central-1.amazonaws.com/polygon.svg"
                  : row?.chains[0]?.chainName == "Ethereum Mainnet"
                  ? "https://clabucket.s3.ca-central-1.amazonaws.com/eth.svg"
                  : row?.chains[0]?.chainName == "Binance Smart Chain "
                  ? "https://clabucket.s3.ca-central-1.amazonaws.com/bnb.svg"
                  : null
              }
              staticImage
            />
          )}
          <span>
            {row?.chains[0]?.chainName
              ? row.chains[0].chainName
              : "Chain Id - " +
                (!row.chainId ? "Null" : parseInt(row?.chainId, 16))}
          </span>
        </>
      ),
      sortable: true,
      // hide: "md",
    },
    // {
    //   name: "Token",
    //   selector: (row) =>console.log(row?.chains),
    //   cell: (row) => (
    //     <>  <span>{row?.chains[0]?.currency?row.chains[0].currency:"Null"}</span></>),
    //   sortable: true,
    //   // hide: "md",
    // },
    // {
    //   name: "Date & time",
    //   selector: (row) => row?.createdAt,
    //   cell: (row) => <span>{ dateFormat(row?.createdAt, "dddd, mmmm dS, yyyy, h:MM:ss TT")}</span>,
    //   sortable: true,
    //   // hide: "lg",
    // },
    {
      name: "Location",
      selector: (row) => row.country_name,
      cell: (row) => <span>{row.city + ", " + row.country_name}</span>,
      sortable: true,
      // hide: "lg",
    },
    {
      name: "URL",
      grow: "2",
      selector: (row) => row.path,
      cell: (row) => <span>{row.path.split("?")[0]}</span>,
      sortable: true,
      // hide: "lg",
    },
    {
      name: "Revenue",
      selector: (row) => row.status,
      cell: (row) => (
        <span style={{ marginLeft: "1%" }}>
          {row.revenue ? row.revenue : 0}
        </span>
      ),
      sortable: true,
    },
    {
      name: "Source",
      selector: (row) => row.status,
      cell: (row) => (
        <span style={{ marginLeft: "1%" }}>
          {row?.refrer ? row?.refrer : row.UTM?.length ?row.UTM[0]?.utm_source?.split("?",1):"NA"}
        </span>
      ),
      sortable: true,
    },
    // {
    //     name: "OS",
    //     cell: (row) => (
    //         <div className="text-end w-100">
    //             <Dropdown>
    //                 <Dropdown.Toggle size="sm" as={CustomDropdownToggle} className="btn btn-sm btn-icon btn-zoom me-n1">
    //                     <Icon name="more-v"></Icon>
    //                 </Dropdown.Toggle>
    //                 <Dropdown.Menu className="dropdown-menu-sm" as={CustomDropdownMenu} align="end">
    //                     <div className="dropdown-content py-1">
    //                         <LinkList className="link-list-hover-bg-primary link-list-md">
    //                             <LinkListItem to={`/user-manage/user-edit/${row.id}`}>
    //                                 <Icon name="edit"></Icon><span>Edit</span>
    //                             </LinkListItem>
    //                             <LinkListItem to={`/user-manage/user-edit/${row.id}`}>
    //                                 <Icon name="trash"></Icon><span>Delete</span>
    //                             </LinkListItem>
    //                             <LinkListItem to={`/user-manage/user-profile/${row.id}`}>
    //                                 <Icon name="eye"></Icon><span>View Details</span>
    //                             </LinkListItem>
    //                         </LinkList>
    //                     </div>
    //                 </Dropdown.Menu>
    //             </Dropdown>
    //         </div>
    //     ),
    //     sortable: false,
    //     hide: "md",
    // },
  ];

  return (
    <Layout title="Events List" content="container-fluid">
      <Block.Head>
        <Block.HeadBetween>
          <Block.HeadContent>
            <Block.Title tag="h2">Events List</Block.Title>
            <nav>
              <ol className="breadcrumb breadcrumb-arrow mb-0">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Events List
                </li>
              </ol>
            </nav>
          </Block.HeadContent>
          {/* <Block.HeadContent>
            <ul className="d-flex">
              <li>
                <Button
                  className="d-md-none"
                  size="md"
                  variant="primary"
                  onClick={handleShowModal}
                >
                  <Icon name="plus" />
                  <span>Add</span>
                </Button>
              </li>
              <li>
                <Button
                  className="d-none d-md-inline-flex"
                  variant="primary"
                  onClick={handleShowModal}
                >
                  <Icon name="plus" />
                  <span>Add User</span>
                </Button>
              </li>
            </ul>
          </Block.HeadContent> */}
        </Block.HeadBetween>
      </Block.Head>

      <Block>
        <Card className="card-stretch">
          <Card.Body>
            {isLoading ? ( // render the loader if isLoading is true
              <div className="d-flex justify-content-center align-items-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : (
              <DataTable data={data} columns={userColumns} />
            )}
          </Card.Body>
        </Card>
      </Block>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form action="#">
            <Row className="g-3">
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="firstname">First Name</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="firstname"
                      type="text"
                      placeholder="First name"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="lastname">Last Name</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="lastname"
                      type="text"
                      placeholder="Last name"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label htmlFor="email">Email Address</Form.Label>
                  <div className="form-control-wrap">
                    <Form.Control
                      id="email"
                      type="text"
                      placeholder="Email address"
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group className="form-group">
                  <Form.Label>Status</Form.Label>
                  <div className="form-control-wrap">
                    <Select removeItemButton>
                      <option value="">Select a status</option>
                      <option value="1">Pending</option>
                      <option value="2">Active</option>
                      <option value="3">Inactive</option>
                    </Select>
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group className="form-group">
                  <Form.Label>Role</Form.Label>
                  <div className="form-control-wrap">
                    <Select removeItemButton>
                      <option value="">Select a role</option>
                      <option value="1">Administrator</option>
                      <option value="2">Developer</option>
                      <option value="3">Analyst</option>
                      <option value="4">Support</option>
                      <option value="5">Trial</option>
                    </Select>
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <div className="d-flex gap g-2">
                  <div className="gap-col">
                    <Button variant="primary" onClick={handleCloseModal}>
                      Add User
                    </Button>
                  </div>
                  <div className="gap-col">
                    <button
                      type="button"
                      className="border-0 btn"
                      onClick={handleCloseModal}
                    >
                      Discard
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </Layout>
  );
}

export default EventsList;
