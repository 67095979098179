import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import exportFromJSON from "export-from-json";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import DataTablePagination from "../Pagination/DataTablePagination";
import { Icon } from "../../components";

// export file component
const Export = ({ data }) => {
  let location = useLocation().pathname;
  const fileName = (location == "/wallet-list" && "wallets-data")||(location.includes("/user-manage/user-profile")) &&"wallets-holding-data" || "app-data" || "user-data";

  const exportCSV = () => {
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
  };

  const exportExcel = () => {
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <div className="export-options d-flex align-items-center me-2">
      <div className="export-title small me-2">Export</div>
      <div className="btn-group">
        <Button variant="outline-light" onClick={() => exportCSV()}>
          CSV
        </Button>
        <Button variant="outline-light" onClick={() => exportExcel()}>
          Excel
        </Button>
      </div>
    </div>
  );
};

// expanded component in mobile view
const expandedComponent = ({ data }) => {
  return (
    <ul className="data-details p-3 gap gy-1 border-bottom small">
      <li>
        <span className="data-title text-base fw-medium me-2">Name:</span>
        <span className="data-text text-light">{data.name}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Age:</span>
        <span className="data-text text-light">{data.age}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Position:</span>
        <span className="data-text text-light">{data.position}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Company:</span>
        <span className="data-text text-light">{data.company}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Start Date:</span>
        <span className="data-text text-light">{data.startDate}</span>
      </li>
      <li>
        <span className="data-title text-base fw-medium me-2">Salary:</span>
        <span className="data-text text-light">{data.salary}</span>
      </li>
    </ul>
  );
};

// custom checkbox
const customCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="form-check" id={rest.name}>
    <input
      type="checkbox"
      className="form-check-input"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
  </div>
));

function DataTableComponentUser({
  data,
  columns,
  searchbar,
  className,
  expandableRows,
  actions,
  tableClassName,
  selectableRows,
  dashboardEVent,
  sourcetraafic,
  dashboardPagination,
  ...props
}) {
  const [tableData, setTableData] = useState(data);
  const [searchText, setSearchText] = useState("");
  const [showItemPerPage, setShowItemPerPage] = useState(
    sourcetraafic || dashboardPagination ? 5 : 10
  );
  const [mobileView, setMobileView] = useState(false);
  // const [val, setVal] = useState([]);
  const [searchJson, setSearchJson] = useState({});
  const [key, setKey] = useState([]);
  const [isTotalvalue, setIstotalValue] = useState(false);

  const [val, setVal] = useState([]);
  const [isTotalValue, setIsTotalValue] = useState(false);

  const handleAdd = () => {
    setVal((prevVal) => [...prevVal, { key: "", value: "" }]);
  };

  const handleChange = (e, index, field) => {
    const { value } = e.target;
    setVal((prevVal) => {
      const updatedVal = [...prevVal];
      updatedVal[index][field] = value;
      return updatedVal;
    });

    if (field === "key" && value === "TotalAssetValue") {
      setIsTotalValue(true);
    } else {
      setIsTotalValue(false);
    }
  };

  const deleteOperation = (index) => {
    setVal((prevVal) => {
      const updatedVal = [...prevVal];
      updatedVal.splice(index, 1);
      return updatedVal;
    });
  };

  const applySearch = () => {
    const filteredData = data.filter((item) => {
      let isValid = false; // Set isValid to false initially

      // Check if all criteria conditions are met
      if (
        val.every((criteria) => {
          if(criteria.key=="chains"){
            // alert("*")
            return item?.chains?.includes(criteria.value.toUpperCase())
          }
          if (criteria.key === "walletID") {
            return item?.walletID?.includes(criteria.value);
          } else if (criteria.key === "TotalAssetValue") {
            const totalValue = parseFloat(criteria.value);
            switch (criteria.condition) {
              case "equal":
                // const parsedTotalValue = parseFloat(totalValue);
                return parseInt(item?.totalValue) === parseInt(totalValue);
              case "lessthan":
                return item?.totalValue < totalValue;
              case "greaterthan":
                return item?.totalValue > totalValue;
              default:
                return parseInt(item?.totalValue) === parseInt(totalValue);
            }
          } else {
            if (criteria.key == "country_name") {
              if (
                item.country_name
                  .toLowerCase()
                  .includes(criteria.value.toLowerCase())
              ) {
                return item.country_name
                  .toLowerCase()
                  .includes(criteria.value.toLowerCase());
              } else {
                return item.city
                  .toLowerCase()
                  .includes(criteria.value.toLowerCase());
              }
            }
          }
        })
      ) {
        isValid = true; // Set isValid to true if all criteria conditions are met
      }

      return isValid;
    });
    setTableData(filteredData);
  };

  // filter items by name
  let location = useLocation().pathname;
  console.log("location",location)
  useEffect(() => {
    const filteredData = data?.filter(
      (item) =>
        item?.walletID?.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.country_name
          ?.toLowerCase()
          .includes(searchText.toLocaleLowerCase()) ||
        item?.city?.toLowerCase().includes(searchText.toLocaleLowerCase())
    );
    setTableData(filteredData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);
  useEffect(() => {
    setTableData(data);
  }, [data?.length]);

  useEffect(() => {
    setTableData(data);
  }, [!searchText]);
  // function to change the table design view to expanable under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 960 && expandableRows) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    return () => {
      window.removeEventListener("resize", viewChange);
    };
  }, []);

  return (
    <div className="data-table-wrapper data-margin">
      {!dashboardEVent && (
        <div className="data-table-top">
          {searchbar && (
            <div className="data-table-search">
              <input
                className="form-control d-none"
                placeholder="Search by name"
                type="text"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          )}
          <div className="data-table-action-wrap">
            {/* {location == "/wallet-list" && (
            <div class="form-check form-switch">
              <label class="form-check-label me-2" for="flexSwitchCheckChecked">
                Page View
              </label>
              <input
                class="form-check-input  me-2"
                type="checkbox"
                checked={pageview}
                id="flexSwitchCheckChecked"
                onChange={pagViewMode}
              />
            </div>
          )} */}
             { actions || location == '/wallet-list' &&  <Export data={tableData} />}
            {!sourcetraafic && (
              <div className="data-table-select">
                <select
                  className="form-select"
                  onChange={(e) => setShowItemPerPage(e.target.value)}
                  value={showItemPerPage}
                >
                  <option value="5">5</option>  
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </select>
                <span className="text">Per page</span>
              </div>
            )}
          </div>
        </div>
      )}
      {/* <div className="data-table-top search-hide" style={{width:"40%"}}>
        {location == "/wallet-list" && (
          <div className="data-table-search d-flex gap-2 align-items-center flex-wrap flex-column">
            {val.length == 0 && (
              <button
                className="btn btn-primary rounded-circle fw-bold"
                onClick={handleAdd}
              >
                +
              </button>
            )}
            {val.map((item, i) => {
              return (
                <>
                  <div className="d-flex justify-content-between gap-2 align-items-center">
                    <select
                      className="form-select event-padding bg-light border-0 opacity-50"
                      name="cars"
                      onChange={(e) => {
                        handleChange(e, i, "key");
                      }}
                    >
                      <option>Select</option>

                      <option value="walletID">Wallet Address</option>


                      <option value="country_name">Location</option>
                      <option value="TotalAssetValue" name="totalAssetValue">TotalAssetValue </option>
                    </select>{" "}
                   { isTotalvalue &&
                     <select
                     className="form-select event-padding bg-light border-0 opacity-50"
                    //  name="cars"
                     onChange={(e) => {
                       handleChange(e, i, "key");
                     }}
                   >

                     <option value="equal">=</option>


                     <option value="lessthen"> &lt; </option>
                     <option value="greaterThen" >&gt; </option>
                   </select>
                   }
                   {!isTotalvalue && <p>=</p>} 
                    <input
                      className="form-control bg-light border-0 opacity-50 event-padding"
                      name=""
                      onChange={(e) => handleChange(e, i, "value")}
                    />
                    {
                      val.length == 0 ||
                      (val.length !== 1 && (
                        <p className="border border-light rounded event-padding m-0">
                          {" "}
                          AND
                        </p>
                      ))}
                    {val.length == 0 ||
                        <button
                          className="btn btn-primary event-padding"
                          onClick={() => {deleteOperation(i);setTableData(data);setIstotalValue(false)}}
                        >
                          <Icon name="trash"></Icon>
                        </button>
                      }
                  </div>
                </>
              );
            })}{" "}
            {val.length != 0 && (
              <button
                className="btn btn-primary rounded-circle fw-bold"
                onClick={handleAdd}
              >
                +
              </button>
            )}{" "}
            {val.length != 0 && (
              <button className="btn btn-primary" style={{cursor:"pointer"}}onClick={applySearch}>
                Filter
              </button>
            )}
          </div>
        )}
      </div> */}
      <div className="data-table-top search-hide" style={{ width: "40%" }}>
        <div className="data-table-search d-flex gap-2 align-items-center flex-wrap flex-column">
{  location!=="/home" &&        val?.length === 0 &&  (
            <button
              className="btn btn-primary rounded-circle fw-bold"
              onClick={handleAdd}
            >
              +
            </button>
          )}
          {val.map((item, i) => (
            <div
              className="d-flex justify-content-between gap-2 align-items-center"
              key={i}
            >
              <select className="form-select event-padding bg-light border-0 opacity-50"
                name="cars"
                onChange={(e) => handleChange(e, i, "key")}>
                <option>Select</option>
                <option value="walletID">Wallet Address</option>
                <option value="country_name">Location</option>
                <option value="chains">Chain Name</option>
                <option value="TotalAssetValue" name="totalAssetValue">
                  Total Value
                </option>
              </select>
              {item.key == "TotalAssetValue" && (
                <select
                  className="form-select event-padding bg-light border-0 opacity-50"
                  onChange={(e) => handleChange(e, i, "condition")}
                >
                  <option value="equal">= Equal to</option>
                  <option value="lessthan">&lt; Less then</option>
                  <option value="greaterthan">&gt; Greater then</option>
                </select>
              )}
              {!isTotalValue && <p>=</p>}
              {item.key == "chains" && (
                <select
                  className="form-select event-padding bg-light border-0 opacity-50"
                  onChange={(e) => handleChange(e, i, "value")}
                >
                  <option >Select Chain</option>
                  <option value="BSC">BSC</option>
                  <option value="ETH">ETH</option>
                  <option value="MATIC">MATIC</option>
                </select>
              )}
          { item.key != "chains"  &&   <input
                className="form-control bg-light border-0 opacity-50 event-padding"
                name=""
                onChange={(e) => handleChange(e, i, "value")}
              />}
              {val?.length !== 1 && (
                <p className="border border-light rounded event-padding m-0">
                  AND
                </p>
              )}
              <button
                className="btn btn-primary event-padding"
                onClick={() => {
                  deleteOperation(i);
                  setIsTotalValue(false);
                  setTableData(data);
                }}
              >
                Delete
              </button>
            </div>
          ))}
          {val?.length !== 0 && (
            <button
              className="btn btn-primary rounded-circle fw-bold"
              onClick={handleAdd}
            >
              +
            </button>
          )}
          {val?.length !== 0 && (
            <button
              className="btn btn-primary"
              style={{ cursor: "pointer" }}
              onClick={applySearch}
            >
              Filter
            </button>
          )}
        </div>
      </div>
      <DataTable
        columns={columns}
        data={tableData}
        className={tableClassName}
        noDataComponent={<div className="p-2">There are no records found.</div>}
        sortIcon={<div className="data-table-sorter"></div>}
        pagination={
          dashboardEVent
            ? false
            : sourcetraafic
            ? true
            : dashboardPagination
            ? true
            : false
        }
        expandableRowsComponent={expandedComponent}
        expandableRows={mobileView}
        selectableRows={selectableRows}
        selectableRowsComponent={customCheckbox}
        paginationComponent={({
          rowsPerPage,
          rowCount,
          onChangePage,
          onChangeRowsPerPage,
          currentPage,
        }) => (
          <div className="data-table-bottom">
            <DataTablePagination
              showItemPerPage={showItemPerPage}
              itemPerPage={rowsPerPage}
              totalItems={rowCount}
              paginate={onChangePage}
              currentPage={currentPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              setShowItemPerPage={setShowItemPerPage}
            />
          </div>
        )}
      />
    </div>
  );
}

export default DataTableComponentUser;
