import React, { useState, useEffect } from 'react';
import Layout from '../../layout/default';
import { useNavigate, useParams} from 'react-router-dom';
import { API_URL } from '../../config';
import axios from 'axios';
import Swal from 'sweetalert2/src/sweetalert2';
import { Col, Form } from 'react-bootstrap';

const ListDetailEdit = () => {
  const { id } = useParams();
    const navigate =  useNavigate();

  const [clientData, setClientData] = useState({
    email: '',
    firstname: '',
    lastname: '',
    stripe_id: '',
    isActive: true,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/client/list/${id}`);
      setClientData(response.data);
      console.log(response.data)
    } catch (error) {
      console.error('Error fetching client data:', error);
    }
  };

  const handleInputChange = (e,type) => {

    let  checkedValue = e.target.checked;

   

    setClientData({
      ...clientData,
      [e.target.name]: e.target.value,
    });

    if (type != undefined && type == "check") {
    
      setClientData({ ...clientData, isActive: checkedValue });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    console.log('edited')
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Update it!'
    }).then(async(result) => {
      if(result.value) {
        try {
          console.log("clicked")
          const res = await axios.put(`${API_URL}/client/edit-list/${id}`, { updatedData: clientData });
          navigate('/list')
        } catch (error) {
          console.error('Error updating client data:', error);
        }
          Swal.fire('Modified', 'You successfully Updated this Notary', 'success')
        } else {
          Swal.fire('Cancelled', 'No changes made to Notary', 'info')
        }
    })
  };

 

  return (
    <Layout title="Edit Client" content="container">
      <h2>Edit Notary</h2>
      <form onSubmit={handleFormSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={clientData.email}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="firstname" className="form-label">
            First Name
          </label>
          <input
            type="text"
            className="form-control"
            id="firstname"
            name="firstname"
            value={clientData.firstname}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="lastname" className="form-label">
            Last Name
          </label>
          <input
            type="text"
            className="form-control"
            id="lastname"
            name="lastname"
            value={clientData.lastname}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="stripe_id" className="form-label">
            Stripe Id
          </label>
          <input
            disabled
            type="text"
            className="form-control"
            id="stripe_id"
            name="stripe_id"
            value={clientData.stripe_id}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="BankAccountVerified" className="form-label">
          Bank Account Verified
          </label>
          <input
            disabled
            type="text"
            className="form-control"
            id="BankAccountVerified"
            name="stripe_id"
            value={clientData.bankDetailComplete?"Yes":"No"}
            onChange={handleInputChange}
            required  
          />
        </div>
        <div className="mb-3">
          <label htmlFor="NotaryPriviliges" className="form-label">
          Notary Priviliges
          </label>
          <input
            disabled
            type="text"
            className="form-control"
            id="NotaryPriviliges"
            name="stripe_id"
            value={clientData.hasNotaryPrivileges?"Yes":"No"}
            
            onChange={handleInputChange}
            required
          />
        </div>


        <div className="mb-3">
        <Form.Check type="switch" id="flexSwitchChecked" label={clientData?.isActive ? "Active" : "Deactivated"} defaultChecked
        name='activate'
         onChange={(e) => handleInputChange(e, "check")}
         checked={clientData?.isActive ? true : false}
   
        />
        </div> 

        <button type="submit" className="btn btn-primary" onClick={handleFormSubmit}>
          Save Changes
        </button>
      </form>
    </Layout>
  );
};

export default ListDetailEdit;
