import { Row, Col, Card } from "react-bootstrap";
import Layout from "../layout/default";

function FAQ() {

  return (
    <Layout title="Invoice" content="container">
      <Row className="g-gs">
        <Col sm="12" xl="12" xxl="12">
          <Card className="h-100">
            <Card.Body>
              <div className="card-title-group">
                <div className="card-title">
                  <h5 className="title">FAQ</h5>
                </div>
              </div>
              <div className="d-flex flex-column mt-3">
                <h5 style={{ marginTop: "1rem" }}>1.	How do I register to become an AYIN Online Notary℠ member?</h5>
                <p className="ms-3">To begin registration,<a href="https://www.ayininternationalinc.org/join-now"> Click Here </a>. Please make sure you have your identification 
                <br />e.g. government issued unexpired driver’s license Notary commission, e-seal, and bank routing number.</p>
                <hr className="text-dark opacity-25" />
                <h5 style={{ marginTop: "1rem" }}>2.	Do you offer membership options? </h5>
                <p className="ms-3">AYIN Online Notary℠ offers 3 membership options. The initial membership option is AYIN Online Notary℠ Business Pro. </p>
                <hr className="text-dark opacity-25" />
                <h5 style={{ marginTop: "1rem" }}>3.	Why are my banking details required for registration? </h5>
                <p className="ms-3">Your banking details are necessary to participate in the AYIN Online Notary℠ invoicing and payment portal. You will be granted access to invoice your clients and be paid via direct deposit into your designated bank account.</p>
                <hr className="text-dark opacity-25" />
                <h5 style={{ marginTop: "1rem" }}>4.	How does AON Invoicing and payment work? </h5>
                <p className="ms-3">Once you affix your notary seal and signature, AYIN Online Notary℠ will redirect you to our invoicing portal which will allow you to create an invoice, input your service item 
                <br/>e.g. acknowledgement, jurat, extra seal, signatures, witness, travel etc… and input your own fees for submission and payment by your clients.</p>
                <hr className="text-dark opacity-25" />
                <h5 style={{ marginTop: "1rem" }}>5.	How long does it take for my membership to activate? </h5>
                <p className="ms-3">Less than 24 hours.</p>
                <hr className="text-dark opacity-25" />
                <h5 style={{ marginTop: "1rem" }}>6.	Do you offer notarial act referrals? </h5>
                <p className="ms-3">Yes, upon availability.</p>
                <hr className="text-dark opacity-25" />
              </div>

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default FAQ;
