import { Link, useNavigate } from 'react-router-dom';
import { Image, Icon, Media, MediaGroup, MediaText, CustomDropdownMenu, CustomDropdownToggle, OverlineTitle, ChartLegend, LinkList, LinkListItem } from '../../components';
import axios from "axios"
import { API_URL } from '../../config';
import Swal from 'sweetalert2/src/sweetalert2';


export const tableColumns = [
  {
    name: "Sr No",
    selector: (row, index) => index + 1,
    sortable: true,
  },
  {
    name: "Amount",
    selector: (row) => row?.amount,
    sortable: true,
  },
  {
    name: "Package ID",
    selector: (row) => row?.notary_id,
    sortable: true,
  },
  {
    name: "Invoice Number",
    selector: (row) => row.invoiceNumber,
    sortable: true,
  },
  {
    name: "Customer Email",
    selector: (row) => row?.email,
    sortable: true,
  },
  {
    name: "Payment Status",
    selector: (row) => row.payment_status,
    cell: (row) => (
      <span className={`badge text-bg-${row?.payment_status === "paid" ? "success"
        : "warning"}`
      }>
        {row?.payment_status ? row?.payment_status : ''}
      </span>
    ),
  },
];

export const tableData = [
  {
    id: 0,
    name: "US$50.60",
    age: "123456-INVOICE",
    position: "admin@gmail.com",
    company: "BUZZWORKS",
    startDate: "2023-12-17",
    salary: "$2,570.39",
    email: "",
    status: "Pending"
  },
  {
    id: 1,
    name: "US$80.60",
    age: "345678-INVOICE",
    position: "Accountant",
    company: "MARQET",
    startDate: "2023-12-26",
    salary: "$1,488.76",
    status: "Sent"
  },
];


function formatDateTime(dateTimeString) {
  const inputDate = new Date(dateTimeString);

  // Function to format the date in "DDMMYY" format
  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(2);

    return `${day}/${month}/${year}`;
  }

  // Function to format the time in AM/PM format
  function formatTime(date) {
    let hours = date.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // 0 baje ke baad 12 baje consider karo
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes} ${ampm}`;
  }

  // Getting the formatted date in "DDMMYY" format
  const formattedDate = formatDate(inputDate);

  // Getting the formatted time in AM/PM format
  const formattedTime = formatTime(inputDate);

  // Combining date and time
  const result = `${formattedDate} ${formattedTime}`;

  // Returning the formatted result
  return result;
}
export const trasaction_tableColumns = [
  {
    name: "PackageId",
    selector: (row) => row.package_id,
    sortable: true,
  },
  {
    name: "Signer name",
    selector: (row) => row.signer_name,
    sortable: true,
  },
  {
    name: "Created At",
    selector: (row) => row?.createdAt,
    cell: (row) => (

      <span >
        {formatDateTime(row?.createdAt)}
      </span>
    ),
  },
  {
    name: "Payment status",
    selector: (row) => row.payment_status,
    cell: (row) => (
      <span >
        {row?.payment_status}
      </span>
    ),
  },
  {
    name: "Notary Name",
    selector: (row) => row.notary_name,
    cell: (row) => (
      <span >
        {row?.notary_name}
      </span>
    ),
  },
  {
    name: "Transaction status",
    selector: (row) => row.package_status,
    cell: (row) => (
      <span >
        {row.package_status ? "Completed" : "In Progress"}
      </span>
    ),
  },
  {
    name: "Invoice",
    selector: (row) => row.package_status,
    cell: (row) => (
      <span >
        {row.package_status ? "Completed" : "In Progress"}
      </span>
    ),
  },
];

export const bankTransactionTable = [
  // {
  //   name: "Sr No",
  //   selector: (row, index) => index + 1,
  //   sortable: true,
  //   // style: {
  //   //   width: "30px",
  //   //   textAlign: "center",
  //   // },
  // },
  {
    name: "SR No",
    selector: (row) => row.name,
    cell: (row, index) => (<><span>{index + 1}</span>
    </>
    ),
    sortable: true,
  },

  // {
  //   name: "Sent By",
  //   selector: (row) => row?.sentBy,
  //   sortable: true,
  // },
  {
    name: "Transaction Type",
    selector: (row) => row?.type ? `Transferred to bank` : "",
    sortable: true,

  },
  {
    name: "Amount",
    selector: (row) => row?.amount,
    cell: (row) => (
      <span >
        {row?.amount}
      </span>
    ),
  },
  {
    name: "Merchant Fee",
    selector: (row) => row?.merchantFee,
    cell: (row) => (
      <span >
        {row?.merchantFee}
      </span>
    ),
  },
  {
    name: "Net Amount",
    selector: (row) => row?.merchantFee,
    cell: (row) => (
      <span >
        {row?.amount - row?.merchantFee}
      </span>
    ),
  },
  {
    name: "Created At",
    selector: (row) => row?.createdAt,
    cell: (row) => (
      <span >
        {formatDateTime(row?.createdAt)}
      </span>
    ),
  },

  // {
  //   name: "Notary Name",
  //   selector: (row) => row.notary_name,
  //   cell: (row) => (
  //     <span >
  //       {row?.notary_name}
  //     </span>
  //   ),
  // },
  // {
  //   name: "Transaction status",
  //   selector: (row) => row.package_status,
  //   cell: (row) => (
  //     <span >
  //       {row.package_status ? "Completed" : "In Progress"}
  //     </span>
  //   ),
  // },
  // {
  //   name: "Invoice",
  //   selector: (row) => row.package_status,
  //   cell: (row) => (
  //     <span >
  //       {row.package_status ? "Completed" : "In Progress"}
  //     </span>
  //   ),
  // },
];

export const trasaction_tableData = [
  {
    package_id: "QDFHLeCx2d1J3BoVIA-qqsno_Ao=",
    signer_name: "RInku",
    notary_name: "123456-INVOICE"
  }
];

var sr_no = 0

export const list_tableColumns = [
  // {
  //   name: "Id",
  //   selector: (row) => row?.uid,
  //   sortable: true,
  // },
  {
    name: "Sr No",
    selector: (row, index) => sr_no = index+1, 
    sortable: true,
  },
  {
    name: "Name",
    selector: (row) => `${row.firstname} ${row.lastname}`,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email
  },
  {
    name: "Subscription Status",
    selector: (row) => row.subscription_id,
    cell: (row) => (
      <span >
        {row.subscription_id ? "Registered" : "Not Registered"}
      </span>
    ),
    cell: (row) => (
      <span className={`badge text-bg-${row.squarePaymentDone ? "success"
        : "warning"}`
      }>
        {row.squarePaymentDone ? "Active" : "Inactive"}
      </span>
    ),
  },
  {
    name: "Account Status",
    selector: (row) => <span className={`badge text-bg-${row.isActive ? "success"
    : "danger"}`
  }> {row.isActive ? "Active" : "Deactivated"}</span>
  },
  {
    name: "Bank Account Verified",
    selector: (row) => row?.bankDetailComplete,
    cell: (row) => (
      <span >
        {row?.bankDetailComplete?"Yes":"No"}
      </span>
    )
  },
   
  {
    name: "Notary Priviliges",
    selector: (row) => row?.hasNotaryPrivileges,
  cell: (row) => (
    <span >
      {row?.hasNotaryPrivileges?"Yes":"No"}
    </span>
  ),
  },
  {
    name: "Actions",
    // selector: (row) => row.subscription_id,
    cell: (row) => (
      <LinkList className="link-list-hover-bg-primary link-list-md" style={{ "display": "flex" }}>
        <span >
          {/* <Link to={`/list/${row.uid}`}>
            <Icon name="eye" className="text-success fs-3 pe-3"></Icon>
          </Link> */}
          <Link to={`/edit-list/${row.uid}`}>
            <Icon name="pen" className="text-success   fs-3 pe-3"></Icon>
          </Link>
          <Link to="/list">
            <a
              href="/list"
              onClick={async () => {
                Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, delete it!'
                }).then(async (result) => {
                  if(result.value) {
                      const res = await axios.delete(`${API_URL}/client/list/${row.uid}`);
                     console.log(res);
                     window.location.reload();
                      Swal.fire('Deleted', 'You successfully deleted this notary', 'success')
                    } else {
                      Swal.fire('Cancelled', 'Your notary is still intact', 'info')
                    }
                })
              }}
            >
              <Icon name="trash" className="text-danger fs-3 pe-3"></Icon>
            </a>
          </Link>
        </span>
      </LinkList>
    ),
  },
  // {
  //   name: "Transaction status",
  //   selector: (row) => row.package_status,
  //   cell: (row) => (
  //     <span >
  //       {row.package_status ? "Completed" : "In Progress"}
  //     </span>
  //   ),
  // },
  // {
  //   name: "Invoice",
  //   selector: (row) => row.package_status,
  //   cell: (row) => (
  //     <span >
  //       {row.package_status ? "Completed" : "In Progress"}
  //     </span>
  //   ),
  // },
];
