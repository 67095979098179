import BaseApi from "..";

class DashboardApi extends BaseApi {
  // async create_subscription(data) {
  //   console.log("data",)
  //   return await this.API({
  //     url: "stripe/stripe_checkout/subscription",
  //     method: "post",
  //     data: data
  //   });
  // };
  // async create_invoice(data) {
  //   console.log("data",)
  //   return await this.API({
  //     url: "invoice/add",
  //     method: "post",
  //     data: data
  //   });
  // };
  async dahboardData(params) {
    return await this.API({
      url: `/client/list/dashboard-all-data?${params}`,
      method: "get",
    });
  };
}

export default DashboardApi;