import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Blank from "../../pages/Blank";
import Home from "../../pages/Home";
import InvoiceList from "../../pages/Invoice/InvoiceList";
import TransactionList from "../../pages/Transaction/list";
import PlanPricing from "../../pages/plans/list";
import Success from "../../pages/stripe/Success";
import ViewProfile from "../../pages/Profile/ViewProfile";
import WalletHistory from "../../pages/WalletHistory/WalletHistory";
import FAQ from "../../pages/FAQ";
import List from "../../pages/List/List";
import ListDetail from "../../pages/List/ListDetail";
import ListDetailEdit from "../../pages/List/ListDetailEdit";
import UsersList from "../../pages/List/UsersList";
function PrivateRoute() {
  
  return (
    <>
      <Routes>
        <Route path="/invoice-list" element={<InvoiceList />} />
        <Route path="/transaction-list" element={<TransactionList />} />
        <Route path="/transaction-list/?package=:package&signer=:signerId&status=:packageStatus" element={<TransactionList />} />
        <Route path="/bank-transactions" element={<WalletHistory />} />
        <Route path="/view-profile" element={<ViewProfile />} />
        <Route path="/view-profile_" element={<ViewProfile />} />
        <Route path="/success" element={<Success />} />
        <Route path="blank" element={<Blank />} />
        <Route path="/*" element={<Navigate to="/list" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/home/success" element={<Home />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/list" element={<List/>} />
        <Route path="/users-list" element={<UsersList/>} />
        <Route path="/list/:id" element={<ListDetail/>} />
        <Route path="/edit-list/:id" element={<ListDetailEdit/>} />
      </Routes>
    </>
  );
}

export default PrivateRoute;















// import React from "react";
// import { Routes, Route, Navigate } from "react-router-dom";
// import Blank from "../../pages/Blank";
// import Home from "../../pages/Home";
// import InvoiceList from "../../pages/Invoice/InvoiceList";
// import TransactionList from "../../pages/Transaction/list";
// import PlanPricing from "../../pages/plans/list";
// import Success from "../../pages/stripe/Success";
// import ViewProfile from "../../pages/Profile/ViewProfile";
// import WalletHistory from "../../pages/WalletHistory/WalletHistory";
// function PrivateRoute() {
  
//   return (
//     <>
//       <Routes>
//         <Route path="/invoice-list" element={<InvoiceList />} />
//         <Route path="/transaction-list" element={<TransactionList />} />
//         <Route path="/transaction-list/:id" element={<TransactionList />} />
//         <Route path="/bank-transactions" element={<WalletHistory />} />
//         <Route path="/view-profile" element={<ViewProfile />} />
//         <Route path="/view-profile_" element={<ViewProfile />} />
//         <Route path="/success" element={<Success />} />
//         <Route path="blank" element={<Blank />} />
//         <Route path="/*" element={<Navigate to="/home" />} />
//         <Route path="/home" element={<Home />} />
//         <Route path="/home/success" element={<Home />} />
//       </Routes>
//     </>
//   );
// }

// export default PrivateRoute;
