import React from 'react'
import Nav from 'react-bootstrap/Nav'

function Footer() {
  return (
    <div className="nk-footer">
        <div className="container-fluid">
            <div className="nk-footer-wrap">
                <div className="nk-footer-copyright"> 
                &copy; 2024 AYIN Online Notary℠ powered by OneSpan is a service division of AYIN International, Inc. All Rights Reserved
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer