import BaseApi from "..";

class AuthApi extends BaseApi {
  async login(data) {
    return await this.API({
      // url: "client/login",
      url: "admin/login",
      method: "post",
      data: data,
    });
  };
  async logout(params) {
    return await this.API({
      url: `client/logout/${params}`,
      method: "get",
    });
  };
  async register(data) {
    return await this.API({
      url: "client/register",
      method: "post",
      data: data,
    });
  }
  async get_user_detail(data) {
    return await this.API({
      url: "client/get_user_detail",
      method: "post",
      data: data,
    });
  }
  async get_user_data() {
    return await this.API({
      url: "client/user-data",
      method: "get",
    });
  }
  async dashboardData(params) {
    return await this.API({
      url: `/client/list/dashboard-all-data?userId=${params}`,
      method: "get",
    });
  };

  async retrieveSquareStatus(userId) {
    return await this.API({
      url: `client/retrieve/square-data/${userId}`,
      method: "get",
    })
  }
  async getNotaryPrivilege(userId) {
    return await this.API({
      url: `client/retrieve/notary-privilege/${userId}`,
      method: "get"
    })
  }

}

export default AuthApi;