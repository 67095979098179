import { Button, Card, Table, Form } from "react-bootstrap";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import { Icon } from "../../components";
import { useEffect, useState } from "react";
import { useContext } from "react";
import Swal from "sweetalert2/src/sweetalert2.js";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/global/reducer";
import Stripe from "stripe";
import StripeApi from "../../api/stripe";
import { plan } from "../Jsons/plans";


function PlanPricing() {
  const currentuser = useSelector(selectUser)
  let Stripe = new StripeApi();
  
  const [count, setCount] = useState(1);
  console.log("currentuser", currentuser.user)
  const createSubscription = async (item) => {
    await Stripe.create_subscription({ ...item, "user_id": currentuser.user._id }).then((res) => {
      if (res.status) {
        // console.log("res0------", res.status)
        window.location.href = res.data.url;
        // window.location.reload();
        // history.push(res.data.url)
      }
    }).catch((err) => console.error(err))
  };
  return (
    <>
      <Card className="card-gutter-md">
        <Card.Body>
          <Table responsive striped bordered hover className="small">
            <thead>
              <tr className=" bg-primary">
                <th></th>
                {plan.length > 0 &&
                  plan.map((item) => {
                    return (
                      <>
                        <th className="text-white">{item.plan_name}</th>
                      </>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div>
                    <p className="mb-0">Select Your Plan</p>
                    {/* <a href="#">
                  <Icon name="video-fill"></Icon> <span>Watch Now</span>
                </a>
                <Icon name="arrow-right-circle-fill"></Icon> */}
                  </div>
                </td>
                {plan.length > 0 &&
                  plan.map((item) => {
                    // console.log("plan =====", item);
                    return (
                      <>
                        <td>
                          <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                            {/* <h5>INR {item?.plan_amount}</h5> */}
                            {(currentuser?.user?.squarePaymentDone && currentuser?.user?.squarePaymentDone === true) ? "Active" : (
                              <a href="https://cobalt-elephant-etja.squarespace.com/join-now">
                                Buy Plan
                              </a>
                            )}
                            {/*
                           {item.product_id == currentuser?.user?.product_id ?
                            "Activated" : <Button
                              variant="primary"
                              size="sm"
                              onClick={() => createSubscription(item)}>
                              BUY
                            </Button>
                          } */}
                          </div>
                        </td>

                      </>
                    );
                  })}
              </tr>
              <tr>
                <td>Plan Validity</td>
                {plan.length > 0 &&
                  plan.map((item) => {
                    return (
                      <>
                        <td>
                          <div className="d-flex flex-column justify-content-center align-items-center gap-1">
                            <p>{item.validity} Year</p>
                            {/* <p>Year</p> */}
                          </div>
                        </td>
                      </>
                    );
                  })}
              </tr>
              <tr>
                <td> Price</td>
                {plan.length > 0 &&
                  plan.map((item) => {
                    return (
                      <>
                        <td>
                          <div className="d-flex flex-column justify-content-center align-items-center gap-1">
                            <p>{item?.plan_price} USD</p>
                            {/* <p>per Student</p> */}
                          </div>
                        </td>
                      </>
                    );
                  })}
              </tr>
              <tr>
                <td>AON session fee</td>
                {plan.length > 0 &&
                  plan.map((item) => {
                    return (
                      <>
                        <td>
                          <div className="d-flex flex-column justify-content-center align-items-center gap-1">
                            <p>{item?.AYON_fee} USD</p>
                          </div>
                        </td>
                      </>
                    );
                  })}
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>);
}

export default PlanPricing;
