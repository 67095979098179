import {
  Row,
  Col,
  Card,
  Dropdown,
  Button,
  Modal,
  Form,
  Table,
  Tooltip,
  OverlayTrigger,
  ListGroup,
} from "react-bootstrap";
import dateFormat from "dateformat";
import { useState, useEffect } from "react";
import axios from "axios";
import { LinkList } from "../components";
import { Select } from "../components";
import { LinkListItem, Pureknob } from "../components";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../layout/default";
import EventsList from "./Activity/EventsList";
import {
  Media,
  MediaGroup,
  MediaText,
  Icon,
  ChartLabel,
  ChartLegend,
  CustomDropdownMenu,
  CustomDropdownToggle,
  OverlineTitle,
  Timeline,
  WorldMap,
} from "../components";
import iso from "iso-3166-1";
import Block from "../components/Block/Block";
import { ChartBar, ChartLine, ChartDoughnut, ChartPie } from "../components/Chart/Charts";
import { Colors } from "../utilities/index";
import hexRGB from "../utilities/hexRGB";
import DataTableComponent from "../components/DataTable/DataTableUser";
import AuthApi from "../api/auth";
import DashboardApi from "../api/dashboard";
import { useSelector } from "react-redux";
import { selectUser, setUser } from "../redux/global/reducer";
import { useDispatch } from "react-redux";
import DataTable from '../components/DataTable/DataTable';
import UsersData, { userColumns } from '../store/users/UsersData';
import Logo from "./Logo";

function Home() {
  let dispatch = useDispatch();
  let currentUser = useSelector(selectUser);
  const [loading, setIsLoading] = useState(true);
  const [regions, setRegions] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [sessionCount, setSessionCount] = useState(0);
  const [activeWallet, setActivewallet] = useState([]);
  const [eventsList, setEventList] = useState([]);
  const [refer, setRefrer] = useState(null);
  const [sourcetraffic, setSourcetraffic] = useState({});
  const [revenue_per_day_sum, setRevenue_per_day_sum] = useState(null);
  const [session_per_day_count, setsession_per_day_count] = useState(null);
  const [sourceTraaficTabledata, setSourceTraffictabledata] = useState([]);
  const [refreshRequired, setRefreshRequired] = useState(false);
  const [removed, setRemoved] = useState(false)
  const [
    get_Countries_without_session_tableData,
    setget_Countries_without_session_tableData,
  ] = useState([]);
  const [
    get_Countries_with_session_tableData,
    setget_Countries_with_session_tableData,
  ] = useState([]);
  let navigate = useNavigate();
  let authApi = new AuthApi();
  useEffect(() => {
    getUserLatestDetai();
    // console.log("----------", sessionStorage.getItem("package_id"))
    if (sessionStorage.getItem("package_id")) {
      navigate(`/transaction-list/${sessionStorage.getItem("package_id")}`)
      sessionStorage.removeItem("package_id")
    }
  }, []);
  const getUserLatestDetai = async () => {

    await authApi.dashboardData(currentUser?.user?._id)
      .then((res) => {
        // console.log("res.data ====", res.data);
        setDashboardData(res.data);

      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const checkAndUpdatePaymentStatus = async () => {
    try {
      await authApi.retrieveSquareStatus(currentUser?.user?._id).then((res) => {
        if (res.success === true && res.data == true) {
          setRefreshRequired(true);
        }
      }).catch((error) => {
        console.log("eror :", error);
      })
    } catch (error) {
      console.log("errr ----", error);
    }
  }

  const getDate = (daysToAdd) => {
    const today = new Date();
    const targetDate = new Date(today.setDate(today.getDate() + daysToAdd));

    // Format the date as desired
    const options = { month: "long", day: "numeric" };
    return targetDate.toLocaleDateString("en-US", options);
  };

  // pie chart data
  let pieChartData = {
    labels: ["Draft", "Completed", "Trash"],
    datasets: [{
      // use light red for trash
      backgroundColor: [Colors.warning, Colors.success, Colors.lightPurple],
      data: [
        dashboardData?.pendingNotarialCount ? dashboardData?.pendingNotarialCount : 1,
        dashboardData?.completeNotarialCount ? dashboardData?.completeNotarialCount : 1,
        dashboardData?.trashNotarialCount ? dashboardData?.trashNotarialCount : 1
      ]
    }],
  };

  // bar chart multiple
  let barChartMultiple = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    legend: true,
    barThickness: 10,
    datasets: [
      {
        borderColor: Colors.primary,
        backgroundColor: Colors.primary,
        label: "Income",
        data: [110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95]
      },
      {
        borderColor: Colors.yellow,
        backgroundColor: Colors.yellow,
        label: "Expense",
        data: [75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125]
      }
    ]
  };


  // total Profit Chart
  let totalProfitChart = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    stacked: true,
    ticksValue: 'k',
    borderDash: [8, 4],
    xGridColor: Colors.white,
    xGridBorderColor: Colors.white,
    yGridBorderColor: Colors.white,
    maxTicksLimit: 20,
    datasets: [
      {
        borderRadius: { topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50 },
        backgroundColor: Colors.primary,
        label: "Total Completed Transactions",
        borderSkipped: false,
        data: [120, 160, 95, 105, 98, 99, 167, 140, 155, 267, 237, 250]

      },
      {
        borderRadius: { topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50 },
        backgroundColor: Colors.warning,
        label: "Total Pending Transactions",
        borderSkipped: false,
        data: [110, 80, 125, 55, 95, 75, 90, 110, 80, 125, 55, 95]
      },
      {
        borderRadius: { topLeft: 50, topRight: 50, bottomLeft: 50, bottomRight: 50 },
        backgroundColor: Colors.info,
        label: "Total Revenue",
        borderSkipped: false,
        data: [75, 90, 110, 80, 125, 55, 95, 75, 90, 110, 80, 125]
      }
    ]
  };

  // total sales knob chart
  let totalSales = {
    size: 120,
    value: 65,
    angleOffset: -0.5,
    angleStart: 0.5,
    angleEnd: 0.5,
    colorFg: Colors.info
  };

  // total revenue Chart
  let totalRevenueChart = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    yAxis: false,
    xAxis: false,
    datasets: [
      {
        tension: .4,
        label: "Total",
        borderColor: Colors.primary,
        backgroundColor: 'transparent',
        borderWidth: 4,
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointHoverBackgroundColor: Colors.primary,
        borderCapStyle: 'round',
        data: [12, 40, 13, 130, 70, 210]
      }
    ]
  };

  // campaign Overview Chart
  let campaignOverviewChart = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    ticksValue: 'k',
    borderDash: [8, 4],
    xGridColor: Colors.white,
    yGridColor: Colors.gray100,
    xGridBorderColor: Colors.gray100,
    yGridBorderColor: Colors.white,
    datasets: [
      {
        tension: 0.4,
        borderWidth: 2,
        borderColor: Colors.primary,
        pointBorderColor: Colors.white,
        pointBackgroundColor: Colors.primary,
        pointHoverBackgroundColor: Colors.primary,
        backgroundColor: hexRGB(Colors.primary, 0.2),
        label: "Social",
        fill: true,
        data: [0, 15, 10, 28, 20, 38, 30, 22, 30, 12, 38, 30]
      }
    ]
  };

  const previousDate1 = getDate(-14);
  const previousDate2 = getDate(-8);
  const currentDate = getDate(0);

  const userColumns1 = [
    {
      name: "Wallet Address",
      selector: (row) => row?.walletID,
      cell: (row) => (
        <MediaGroup>
          {/* <Media size="md" shape="circle" variant={row.theme && row.theme}>
            { row.avatar ? 
                <Image src={row.avatar} staticImage/> :
                <span className="smaller fw-medium">{toInitials(row.name)}</span>
            }
            </Media> */}
          <MediaText>
            <Link
              to={`/user-manage/user-profile/${row?._id}`}
              className="title"
            >
              <b>{row?._id?.slice(0, 4) + "......." + row?._id?.slice(-4)}</b>
            </Link>
            {/* <span className="small text">{row?.walletID}</span> */}
          </MediaText>
        </MediaGroup>
      ),
      sortable: true,
    },
    // {
    //   name: "Location",
    //   selector: (row) => row?.country_name,
    //   cell: (row) => (
    //     <>
    //       {" "}
    //       <span>{row?.city + "," + row?.country_name}</span>
    //     </>
    //   ),
    //   sortable: true,
    //   // hide: "lg",
    // },
    //   {
    //     name: "URL",
    //     // grow:"2",
    //     selector: (row) => row.path,
    //     cell: (row) => <span>{row.path}</span>,
    //     sortable: true,
    //     // hide: "lg",
    //   },
    //   {
    //     name: "OS",
    //     selector: (row) => row.status,
    //     cell: (row) => <span style={{ marginLeft: "1%" }}>{row.OS}</span>,
    //     sortable: true,
    //   },

    // {
    //   name: "joined date",
    //   grow: 2,
    //   selector: (row) => row?.createdAt,
    //   cell: (row) => (
    //     <span>
    //       {dateFormat(row?.createdAt, "dddd, mmmm dS, yyyy, h:MM:ss TT")}
    //     </span>
    //   ),
    //   sortable: true,
    //   hide: "lg",
    // },
    // {
    //     name: "status",
    //     selector: (row) => row?.status,
    //     cell: (row) => (
    //         <span className={`badge text-bg-${
    //             row?.status === "Active" ? "success-soft"
    //             : row?.status === "Pending" ? "warning-soft"
    //             : row?.status === "Inactive" ? "secondary-soft"
    //             : "primary-soft"}`
    //         }>
    //         {row?.status ? row?.status : 'General'}
    //         </span>
    //     ),
    //     sortable: true,
    // },
    // {
    //   name: "status",
    //   selector: (row) => row?.status,
    //   cell: (row) => (
    //     <span
    //       className={`badge text-bg-${
    //         row?.status === "Active" ? "success-soft" : "success-soft"
    //       }`}
    //     >
    //       {row?.status ? row?.status : "Active"}
    //     </span>
    //   ),
    //   sortable: true,
    // },
    {
      name: "Events",
      selector: (row) => row?.status,
      cell: (row) => <span>{row?.status ? row?.status : row.count}</span>,
      sortable: true,
    },
  ];
  const eventsColums = [
    {
      name: "Event Name",
      selector: (row) => row?._id,
      cell: (row) => (
        <MediaGroup>
          <MediaText>
            <b>{row?._id != "Revenue" && row?._id}</b>
          </MediaText>
        </MediaGroup>
      ),
      sortable: true,
    },
    {
      name: "Count",
      selector: (row) => row?.status,
      cell: (row) => <span>{row?._id != "Revenue" && row?.count}</span>,
      sortable: true,
    },
    {
      name: "Revenue",
      selector: (row) => row?.revenue,
      cell: (row) => <span>{row?.revenue}</span>,
      sortable: true,
    },
  ];
  const sourceTrafficColumn = [
    {
      name: "Session",
      selector: (row) => row?.session,
      cell: (row) => (
        <MediaGroup>
          <MediaText>
            <b>{row?.session}</b>
          </MediaText>
        </MediaGroup>
      ),
      sortable: true,
    },
    {
      name: "Count",
      selector: (row) => row?.status,
      cell: (row) => <span>{row?.count}</span>,
      sortable: true,
    },
  ];
  const regionColums = [
    {
      name: "Top Region",
      selector: (row) => row?._id,
      cell: (row) => (
        <MediaGroup>
          <MediaText>
            <b>{row?._id}</b>
          </MediaText>
        </MediaGroup>
      ),
      sortable: true,
    },
    {
      name: "Visitor",
      selector: (row) => row?.status,
      cell: (row) => <span>{row?.count}</span>,
      sortable: true,
    },
  ];
  let eventChart = {
    labels: revenue_per_day_sum?.map((item) => {
      const dateString = item._id.day + "/" + item._id.month;
      const [day, month] = dateString.split("/");

      // Use Date object with current year and month-1 (since month starts from 0 in JS Date object)
      const date = new Date(new Date().getFullYear(), month - 1, day);

      // Use toLocaleString method with custom formatting option to get "12 May" format
      const formattedDate = date.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
      });

      return formattedDate;
    }),
    xAxis: false,
    borderDash: [8, 4],
    barThickness: 8,
    datasets: [
      {
        borderRadius: 2,
        borderColor: Colors.primary,
        backgroundColor: Colors.primary,
        label: "Revenue",
        data: revenue_per_day_sum?.map((item) => item.revenue),
      },
    ],
  };

  // sessions chart
  let sessionChart = {
    labels: session_per_day_count?.map((item) => {
      const dateString = item._id.day + "/" + item._id.month;
      const [day, month] = dateString.split("/");

      // Use Date object with current year and month-1 (since month starts from 0 in JS Date object)
      const date = new Date(new Date().getFullYear(), month - 1, day);

      // Use toLocaleString method with custom formatting option to get "12 May" format
      const formattedDate = date.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
      });

      return formattedDate;
    }),
    xAxis: false,
    borderDash: [8, 4],
    barThickness: 8,
    datasets: [
      {
        borderRadius: 2,
        borderColor: Colors.primary,
        backgroundColor: Colors.primary,
        label: "Session",
        data: session_per_day_count?.map((item) => item.count),
      },
    ],
  };

  // session avg chart
  let sessionChartAvg = {
    labels: [
      "1st May",
      "2nd May",
      "3rd May",
      "4th May",
      "5th May",
      "6th May",
      "7th May",
      "8th May",
      "9th May",
      "10th May",
      "11th May",
      "12th May",
      "13th May",
      "14th May",
      "15th May",
      "16th May",
      "17th May",
      "18th May",
      "19th May",
      "20th May",
      "21th May",
      "22th May",
      "23th May",
      "24th May",
      "25th May",
      "26th May",
      "27th May",
      "28th May",
      "29th May",
      "30th May",
    ],
    yAxis: false,
    xAxis: false,
    datasets: [
      {
        tension: 0.4,
        label: "Avg.Sessions",
        borderColor: Colors.midPink,
        borderWidth: 2,
        backgroundColor: hexRGB(Colors.midPink, 0.2),
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        pointHoverBackgroundColor: Colors.midPink,
        fill: true,
        data: [
          120, 140, 140, 130, 128, 130, 145, 140, 132, 135, 145, 155, 165, 180,
          185, 178, 166, 160, 165, 160, 155, 170, 190, 205, 220, 236, 250, 240,
          235, 240,
        ],
      },
    ],
  };

  // Bounce rate chart
  let bounceRateChart = {
    labels: [
      "1st May",
      "2nd May",
      "3rd May",
      "4th May",
      "5th May",
      "6th May",
      "7th May",
    ],
    yAxis: false,
    xAxis: false,
    maxTicksLimit: 4,
    datasets: [
      {
        tension: 0,
        label: "This Month",
        borderWidth: 2,
        borderColor: Colors.midYellow,
        backgroundColor: hexRGB(Colors.midYellow, 0.2),
        pointBackgroundColor: Colors.midYellow,
        pointBorderColor: Colors.white,
        fill: true,
        data: [120, 130, 110, 105, 115, 108, 118],
      },
    ],
  };

  // goal completions chart
  let goalCompletions = {
    labels: [
      "1st May",
      "2nd May",
      "3rd May",
      "4th May",
      "5th May",
      "6th May",
      "7th May",
      "8th May",
      "9th May",
      "10th May",
      "11th May",
      "12th May",
      "13th May",
      "14th May",
      "15th May",
      "16th May",
      "17th May",
      "18th May",
      "19th May",
      "20th May",
      "21th May",
      "22th May",
      "23th May",
      "24th May",
      "25th May",
      "26th May",
      "27th May",
      "28th May",
      "29th May",
      "30th May",
    ],
    yAxis: false,
    xAxis: false,
    barThickness: 5,
    barPercentage: 0.7,
    datasets: [
      {
        borderRadius: 2,
        borderWidth: 1,
        borderColor: Colors.midPurple,
        backgroundColor: Colors.midPurple,
        label: "data",
        data: [
          36, 41, 49, 72, 39, 49, 39, 38, 59, 80, 62, 33, 62, 60, 114, 98, 54,
          39, 72, 39, 49, 39, 38, 59, 80, 62, 33, 62, 60, 114,
        ],
      },
    ],
  };

  // audience overview chart
  let audienceOverviewChart = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "April",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    ticksValue: "k",
    borderDash: [8, 4],
    datasets: [
      {
        tension: 0.4,
        borderWidth: 2,
        borderColor: Colors.midYellow,
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        pointHoverBackgroundColor: Colors.midYellow,
        label: "New visits",
        data: [28, 60, 30, 65, 60, 95, 90, 100, 96, 120, 105, 134],
      },
      {
        tension: 0.4,
        borderWidth: 2,
        pointBorderColor: "transparent",
        pointBackgroundColor: "transparent",
        borderColor: Colors.primary,
        pointHoverBackgroundColor: Colors.primary,
        label: "Unique visits",
        data: [70, 44, 49, 78, 39, 49, 39, 38, 59, 80, 56, 44],
      },
    ],
  };
  // Sessions Device
  let sessionsDevice = {
    labels: sourcetraffic?.source,
    datasets: [
      {
        backgroundColor: [
          Colors.primary,
          Colors.midYellow,
          Colors.midPink,
          Colors.midPurple,
          Colors.black,
          Colors.success,
          Colors.lightVine,
          Colors.danger,
        ],
        data: sourcetraffic?.counts,
        hoverOffset: 4,
      },
    ],
  };

  // referral  data
  let referralData = [
    {
      id: "uid01",
      theme: "dark",
      icon: "github-circle",
      website: "github.com",
      visitor: "3.4K",
      revenue: "$3.877",
      sale: "267",
      conversion: "4.7%",
    },
    {
      id: "uid02",
      theme: "info",
      icon: "twitter",
      website: "twitter.com",
      visitor: "2.5K",
      revenue: "$3.426",
      sale: "265",
      conversion: "4.4%",
    },
    {
      id: "uid03",
      theme: "danger",
      icon: "google",
      website: "google.com",
      visitor: "2.0K",
      revenue: "$2.444",
      sale: "264",
      conversion: "4.7%",
    },
    {
      id: "uid04",
      theme: "info",
      icon: "vimeo",
      website: "vimeo.com",
      visitor: "1.9K",
      revenue: "$2.877",
      sale: "261",
      conversion: "3.6%",
    },
    {
      id: "uid05",
      theme: "warning",
      icon: "digital-ocean",
      website: "digital-ocean.com",
      visitor: "1.8K",
      revenue: "$2.870",
      sale: "260",
      conversion: "3.4%",
    },
  ];

  // svg world map
  let svgWorldMap = {
    colorMax: "#B1C7EF",
    colorMin: "#D4DEEF",
    colorNoData: "#F3F4F6",
    data: {
      data: {
        visitor: {
          name: "Total Sessions",
          format: "{0}",
          thousandSeparator: ",",
          thresholdMax: 50000,
          thresholdMin: 1000,
        },
        change: {
          name: "Changes This Week",
          format: "{0} %",
        },
      },
      applyData: "visitor",
    },
  };
  let svgWorldMap1 = {
    colorMax: "#B1C7EF",
    colorMin: "#D4DEEF",
    colorNoData: "#F3F4F6",
    data: {
      data: {
        visitor: {
          name: "Total Sessions",
          format: "{0}",
          thousandSeparator: ",",
          thresholdMax: 50000,
          thresholdMin: 1000,
        },
        change: {
          name: "Changes This Week",
          format: "{0} %",
        },
      },
      applyData: "visitor",
      values: { ...regions },
    },
  };

  // top channels data
  let channelsData = [
    {
      id: "uid01",
      theme: "dark",
      icon: "github-circle",
      website: "github.com",
      visitor: "3.4K",
      revenue: "$3.877",
      conversion: "4.7%",
    },
    {
      id: "uid02",
      theme: "info",
      icon: "twitter",
      website: "twitter.com",
      visitor: "2.5K",
      revenue: "$3.426",
      conversion: "4.4%",
    },
    {
      id: "uid03",
      theme: "danger",
      icon: "google",
      website: "google.com",
      visitor: "2.0K",
      revenue: "$2.444	",
      conversion: "4.7%",
    },
    {
      id: "uid04",
      theme: "info",
      icon: "vimeo",
      website: "vimeo.com",
      visitor: "1.9K",
      revenue: "$2.877",
      conversion: "3.6%",
    },
    {
      id: "uid05",
      theme: "danger",
      icon: "youtube",
      website: "youtube.com",
      visitor: "1.8K",
      revenue: "$2.870",
      conversion: "3.4%",
    },
    {
      id: "uid06",
      theme: "info",
      icon: "facebook-f",
      website: "facebook.com",
      visitor: "1.5K",
      revenue: "$2.543",
      conversion: "2.6%",
    },
  ];


  return (
    <Layout title="Home" content="container">
      <Row className="g-gs">
        <Col sm="6" xl="4" xxl="4">
          <Card className="h-100">
            <Card.Body>
              <div className="card-title-group">
                <div className="card-title">
                  <h5 className="title">Notarial Transactions</h5>
                </div>
                <ChartLegend.Group className="justify-content-around flex-wrap gap g-2">
                  <ChartLegend symbol="success">
                    Completed
                  </ChartLegend>
                  <ChartLegend symbol="warning">
                    Draft
                  </ChartLegend>
                </ChartLegend.Group>
              </div>
              <div className="nk-chart-analytics-session-device mt-4">
                <ChartPie data={pieChartData} />
              </div>

            </Card.Body>
          </Card>
        </Col>
        {/* <Col sm="6" xl="4" xxl="4">
          <Card className="h-100">
            <Card.Body>
              <Media shape="circle" variant="primary" className="mb-3">
                <Icon name="trend-up"></Icon>
              </Media>
              <h5>Total Transactions</h5>
              <div className="d-flex align-items-center mb-3">
                <div className="amount h4 mb-0">$ {dashboardData?.totalTransactions}</div>
              </div>
            </Card.Body>
          </Card>
        </Col> */}
        <Col sm="6" xl="4" xxl="4">
          <Card className="h-100">
            <Card.Body>
              <Media shape="circle" variant="primary" className="mb-3">
                <Icon name="sign-mxn"></Icon>
              </Media>
              <h5>Total Revenue</h5>
              <div className="d-flex align-items-center mb-3">
                <div className="amount h4 mb-0">$ {dashboardData?.AllRevenue}</div>
                {/* <div className="change up smaller ms-1">
                      <Icon name="plus"></Icon> 18%
                    </div> */}
              </div>
              <div className="nk-chart-overview">
                <ChartBar data={barChartMultiple} />
              </div>
              {/* <p className="small">Total Pending</p> */}
            </Card.Body>
          </Card>
        </Col>
        {((currentUser?.user?.squarePaymentDone === false /* && currentUser?.user?.bankDetailComplete === false*/)) ?
          <Col sm="6" xl="4" xxl="4">
            <Card className="h-100">
              <Card.Body>
                <Logo />
                {/* <Media shape="circle" variant="primary" className="mb-3">
                  <Icon name="sign-mxn"></Icon>
                </Media> */}
                <p>
                  <a href="https://cobalt-elephant-etja.squarespace.com/" target="_blank">
                    Click here to Purchase a Plan for Further Access
                  </a>

                  {/* <h5 style={{ textAlign: 'center' }}>And</h5> */}
                  {/* <a onClick={() => checkAndUpdatePaymentStatus()}>
                    If You have already purchased, click here
                  </a> */}

                  {/* <a href="https://console.ayininternationalinc.org/view-profile" >
                    Click here to Complete your Profile.
                  </a> */}
                </p>
                <div className="d-flex align-items-center mb-3" style={{ fontSize: "18px" }}>
                  <Icon name="check-circle-cut"></Icon> <Icon name="alert-circle" className="ms-2"></Icon> <span className="ms-2">By Plan</span>
                </div>
                <div className="d-flex align-items-center mb-3" style={{ fontSize: "18px" }}>
                  <Icon name="check-circle-cut"></Icon> <Icon name="alert-circle" className="ms-2"></Icon> <span className="ms-2">Bank - Bank Detail Verification</span>
                </div>
                <div className="d-flex align-items-center mb-3" style={{ fontSize: "18px" }}>
                  <Icon name="check-circle-cut"></Icon> <Icon name="alert-circle" className="ms-2"></Icon> <span className="ms-2">Notary Detail</span>
                </div>
                <Button variant="primary">Start Notary</Button>
              </Card.Body>
            </Card>
          </Col>
          :
          (currentUser?.user?.bankDetailComplete === false) ?
            <Col sm="6" xl="4" xxl="4">
              <Card className="h-100">
                <Card.Body>
                  <Media shape="circle" variant="primary" className="mb-3">
                    <Icon name="sign-mxn"></Icon>
                  </Media>
                  <p>
                    <a href="https://console.ayininternationalinc.org/view-profile" >
                      Click here to Complete your Profile.
                    </a>
                  </p>

                  <div className="d-flex align-items-center mb-3" style={{ fontSize: "18px" }}>
                    <Icon name="check-circle-cut"></Icon> <Icon name="alert-circle" className="ms-2"></Icon> <span className="ms-2">By Plan</span>
                  </div>
                  <div className="d-flex align-items-center mb-3" style={{ fontSize: "18px" }}>
                    <Icon name="check-circle-cut"></Icon> <Icon name="alert-circle" className="ms-2"></Icon> <span className="ms-2">Bank - Bank Detail Verification</span>
                  </div>
                  <div className="d-flex align-items-center mb-3" style={{ fontSize: "18px" }}>
                    <Icon name="check-circle-cut"></Icon> <Icon name="alert-circle" className="ms-2"></Icon> <span className="ms-2">Notary Detail</span>
                  </div>
                  <Button variant="primary">Start Notary</Button>
                </Card.Body>
              </Card>
            </Col>
            : <Col sm="6" xl="4" xxl="4">
              <Card className="h-100">
                <Card.Body>
                  <Media shape="circle" variant="primary" className="mb-3">
                    <Icon name="sign-mxn"></Icon>
                  </Media>
                  <h5>
                    {/* <a href="https://console.ayininternationalinc.org/view-profile" target="_blank">
                    Click here to Complete your Profile.
                  </a> */}

                    <a href="https://apps.e-signlive.com/a/transactions/inbox" target="_blank">
                      You can now start notary transactions by clicking here
                    </a>
                  </h5>

                  <div className="d-flex align-items-center mb-3" style={{ fontSize: "18px" }}>
                    <Icon name="check-circle-cut"></Icon> <Icon name="alert-circle" className="ms-2"></Icon> <span className="ms-2">By Plan</span>
                  </div>
                  <div className="d-flex align-items-center mb-3" style={{ fontSize: "18px" }}>
                    <Icon name="check-circle-cut"></Icon> <Icon name="alert-circle" className="ms-2"></Icon> <span className="ms-2">Bank - Bank Detail Verification</span>
                  </div>
                  <div className="d-flex align-items-center mb-3" style={{ fontSize: "18px" }}>
                    <Icon name="check-circle-cut"></Icon> <Icon name="alert-circle" className="ms-2"></Icon> <span className="ms-2">Notary Detail</span>
                  </div>
                  <Button variant="primary">Start Notary</Button>
                </Card.Body>
              </Card>
            </Col>
        }


      </Row>

      <Row className="d-none">
        <Col xxl="8">
          <Card className="h-100">
            <Row>
              <Col md="8">
                <Card.Body>
                  <div className="card-title-group mb-4">
                    <div className="card-title">
                      <h4 className="title">Total Transactions</h4>
                    </div>
                  </div>
                  <div className="nk-chart-ecommerce-total-profit">
                    <ChartBar data={totalProfitChart} />
                  </div>
                </Card.Body>
              </Col>
              <Col md="4">
                <Card.Body>
                  <div className="total-profit-data">
                    <div className="amount-wrap pb-4">
                      <div className="amount h2 mb-0 fw-bold">$842.50k</div>
                      <span className="smaller">Total Transactions Last Month $428.20k</span>
                    </div>
                    <ul className="nk-data-list-group d-flex flex-column flex-sm-row flex-md-column gap g-4">
                      <li className="nk-data-list-item">
                        <Media shape="circle" variant="primary-soft">
                          <Icon name="coins"></Icon>
                        </Media>
                        <div className="amount-wrap">
                          <div className="amount h3 mb-0">$68,740</div>
                          <span className="smaller">Monthly Completed</span>
                        </div>
                      </li>
                      <li className="nk-data-list-item">
                        <Media shape="circle" variant="warning-soft">
                          <Icon name="trend-up"></Icon>
                        </Media>
                        <div className="amount-wrap">
                          <div className="amount h3 mb-0">$38,643</div>
                          <span className="smaller">Monthly Pending</span>
                        </div>
                      </li>
                      <li className="nk-data-list-item">
                        <Media shape="circle" variant="info-soft">
                          <Icon name="coin-alt"></Icon>
                        </Media>
                        <div className="amount-wrap">
                          <div className="amount h3 mb-0">$12,836</div>
                          <span className="smaller">Monthly Revenue</span>
                        </div>
                      </li>
                    </ul>
                    {/* <div className="pt-5">
                      <Button href="#" variant="primary">View Report</Button>
                    </div> */}
                  </div>
                </Card.Body>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xxl="4">
          <Row className="g-gs">
            <Col sm="6" xl="3" xxl="6">
              <Card className="h-100">
                <Card.Body>
                  <Media shape="circle" variant="primary" className="mb-3">
                    <Icon name="trend-up"></Icon>
                  </Media>
                  <h5>Completed</h5>
                  <div className="d-flex align-items-center mb-3">
                    <div className="amount h4 mb-0">$14.3k</div>
                    <div className="change up smaller ms-1">
                      <Icon name="plus"></Icon> 12%
                    </div>
                  </div>
                  <p className="small">Total Completed</p>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" xl="3" xxl="6">
              <Card className="h-100">
                <Card.Body>
                  <Media shape="circle" variant="warning" className="mb-3">
                    <Icon name="sign-mxn"></Icon>
                  </Media>
                  <h5>Pending</h5>
                  <div className="d-flex align-items-center mb-3">
                    <div className="amount h4 mb-0">$37.2k</div>
                    <div className="change up smaller ms-1">
                      <Icon name="plus"></Icon> 18%
                    </div>
                  </div>
                  <p className="small">Total Pending</p>
                </Card.Body>
              </Card>
            </Col>
            <Col sm="6" xl="3" xxl="6">
              <Card className="h-100">
                <Card.Body>
                  <Media shape="circle" variant="info" className="mb-3">
                    <Icon name="sign-mxn"></Icon>
                  </Media>
                  <h5>Revenue</h5>
                  <div className="d-flex align-items-center mb-3">
                    <div className="amount h4 mb-0">$37.2k</div>
                    <div className="change up smaller ms-1">
                      <Icon name="plus"></Icon> 18%
                    </div>
                  </div>
                  <p className="small">Total Revenue</p>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col sm="6" xl="3" xxl="6">
              <Card className="h-100">
                <Card.Body className="text-center">
                  <div className="amount h4 mb-0">$64.35k</div>
                  <div className="nk-chart-ecommerce-total-revenue">
                    <ChartLine data={totalRevenueChart} />
                  </div>
                  <p className="small mt-3">Total Revenue</p>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="12" className="my-3">
          <Card>
            <Card.Body>
              <div className="card-title-group align-items-start">
                <div className="card-title">
                  <h4 className="title">Total Clients</h4>
                </div>
              </div>
              <DataTable tableClassName="data-table-head-light table-responsive" data={dashboardData?.clientDetails} columns={userColumns} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default Home;
