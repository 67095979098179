import { Link } from "react-router-dom";
import { Tab, Nav, Card, Button, Alert, Row, Col } from "react-bootstrap";
import WalletApi from "../../api/wallet";
import { API_URL } from "../../config";
import Layout from "../../layout/default";
import Block from "../../components/Block/Block";
import {
  Image,
  Icon,
  Schedule,
  Media,
  MediaGroup,
  MediaText,
  MediaAction,
} from "../../components";
import { useSelector } from "react-redux";
import { selectUser, setUser } from "../../redux/global/reducer";
import { plan } from "../Jsons/plans";
import PlanPricing from "../plans/list";
import { useEffect, useState } from "react";
import Stripe from "stripe";
import Swal from "sweetalert2/src/sweetalert2";
import Logo from "../Logo";
import { useDispatch } from "react-redux";
import AuthApi from "../../api/auth";
import StripeApi from "../../api/stripe";
function ViewProfile() {
  let { user } = useSelector(selectUser);
  let Stripe = new StripeApi();
  const [activeTab, setActiveTab] = useState("tabOne");

  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  const [bankDetail, setBankDetails] = useState(null);
  const [notaryDetails, setNotaryDetails] = useState(false);  
  const [isStripeOnboardingComplete, setIsStripeOnboardingComplete] =
    useState(false);
  let walletApi = new WalletApi();
  let api = new AuthApi();
  useEffect(() => {
    get_user_data();
    getBankDetailFromStripe();
    checkNotaryPrivileges();
    if (window?.location.pathname.includes("view-profile_")) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Buy Plan first!",
      });
      setActiveTab("tabThree");
    }
    // Check if the user stripe onboarding is successful or not
    checkOnboardingStatus();
  }, []);
  const get_user_data = async () => {
    await api
      .get_user_data()
      .then((res) => {
        console.log("ress========", res);
        dispatch(setUser(res.data));
        localStorage.setItem("user_AYIN", JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getBankDetailFromStripe = async () => {
    // Make API call to backend to get bank details
    Stripe.getBankDetails({ stripe_id: user?.stripe_id })
      .then((res) => {
        // console.log("bank details res111", res);
        if (res.success === true) {
          // console.log("bank details set successfully");
          setBankDetails(res.data);
        } else {
          setBankDetails(null);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const checkNotaryPrivileges = async () => {
    await api.getNotaryPrivilege(user?._id).then((res) => {
      console.log("res of getNotaryPrivilege---", res);
      if (res.data === true) {
        setNotaryDetails(true);
        // console.log("notaryDetails---", notaryDetails);
      } else {
        setNotaryDetails(false);
      }
      // console.log("notaryDetails---", notaryDetails);
    }).catch((errr) => {
      console.log("errr: ", errr);
    })

  }
  var firstChar = user?.firstname.charAt(0).toUpperCase();
  var secondChar = user?.lastname.charAt(0).toUpperCase();
  console.log("bankDetail", bankDetail);
  const dispatch = useDispatch();

  const redeemFromWallet = async () => {
    await walletApi
      .redeemFromWallet(user?._id)
      .then((res) => {
        console.log("resss", res);
        if (res.success) {
          dispatch(setUser(res.data));
          localStorage.setItem("user_AYIN", JSON.stringify(res.data));
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Amount Redeemed Successfully!",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Function to check if the user strip onboarding is successful or not
  const checkOnboardingStatus = async () => {
    // /stripe_account_verified/status
    console.log("userrrr", user);
    // If user.acctVerified is true then no need to check for stripe onboarding status
    if (user.acctVerified) {
      setIsStripeOnboardingComplete(true);
    } else {
      await Stripe.checkOnboardingStatus({ stripe_id: user?.stripe_id })
        .then((res) => {
          // console.log("res111", res);
          if (res?.status) {
            setIsStripeOnboardingComplete(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    //   await stripe.accounts
    //     .retrieve(user?.stripe_id)
    //     .then((account) => {
    //       console.log("account", account);
    //       let result = account.capabilities.transfers !== "active" ? false : true;
    //       // Update state

    //       // if (!result) {
    //       //   Swal.fire({
    //       //     icon: "error",
    //       //     title: "Oops",
    //       //     text: `Please Complete Your Stripe Onboarding`,
    //       //     button: "Complete Now",
    //       //   });
    //       // }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
  };

  console.log("user jp", user);
  return (
    <Layout title="My Profile" content="container">
      <Block.Head>
        <Block.HeadBetween className="align-items-start">
          <Block.HeadContent>
            <div className="d-flex flex-column flex-md-row align-items-md-center">
              <Media size="huge" shape="circle">
                <Logo />
              </Media>
              <div className="mt-3 mt-md-0 ms-md-3">
                <h3 className="title mb-1">
                  {user?.firstname} {user?.lastname}
                </h3>
                <span className="small">Owner </span> <br></br>
                <span className="small">{user?.email}</span>
                <ul className="nk-list-option pt-1">
                  <li>
                    <Icon name="map-pin"></Icon>
                    <span className="small">California, United States</span>
                  </li>

                  <li>
                    <Icon name="building"></Icon>
                    <span className="small">AYIN</span>
                  </li>
                </ul>
                <ul className="nk-list-option pt-1">
                  <li>
                    <span>
                      {user?.acctVerified && (
                        <>
                          <img
                            src="/images/verified.svg"
                            style={{ width: "19px" }}
                          ></img>
                          <span> Payment Verified</span>
                        </>
                      )}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </Block.HeadContent>
        </Block.HeadBetween>
      </Block.Head>

      <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
        <Block.HeadBetween>
          <div className="gap-col">
            <Nav variant="pills" className="nav-pills-border gap g-3">
              <Nav.Item>
                <Nav.Link eventKey="tabTwo">Notary Details</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabOne">Bank Details</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="tabTwo">Notary Details</Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey="tabThree">Membership</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tabFour">Wallet</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
        </Block.HeadBetween>

        <Block className="mt-4">
          <Tab.Content>
            <Tab.Pane eventKey="tabOne">
              <Card className="card-gutter-md">
                <div className="card-row card-row-lg col-sep col-sep-lg">
                  {isStripeOnboardingComplete ? (
                    <div className="card-content col-sep w-50">
                      <Card.Body>
                        <div className="bio-block">
                          <ul className="list-group list-group-borderless small">
                            <li className="list-group-item">
                              <span className="title fw-bold w-40 d-inline-block">
                                Bank Name :{" "}
                              </span>
                              <span className="text">
                                {bankDetail?.bank_name}{" "}
                              </span>
                            </li>
                            <li className="list-group-item">
                              <span className="title fw-bold w-40 d-inline-block">
                                Account Number :{" "}
                              </span>
                              <span className="text">
                                {bankDetail?.account}
                              </span>
                            </li>
                            <li className="list-group-item">
                              <span className="title fw-bold w-40 d-inline-block">
                                Routing Number :{" "}
                              </span>
                              <span className="text">
                                {bankDetail?.routing_number}
                              </span>
                            </li>
                            <li className="list-group-item">
                              <span className="title fw-bold w-40 d-inline-block">
                                Currency :{" "}
                              </span>
                              <span className="text">
                                {bankDetail?.currency}
                              </span>
                            </li>
                            <li className="list-group-item">
                              <span className="title fw-bold w-40 d-inline-block">
                                Country :{" "}
                              </span>
                              <span className="text">
                                {bankDetail?.country}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </Card.Body>
                    </div>
                  ) : (
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => window.open(`${API_URL}stripe/refreshStripeURL?account_id=` + user?.stripe_id + "&userId=" + user?._id, '_blank')}
                    // onClick={() =>
                    // (window.location.href =
                    //   `${API_URL}stripe/refreshStripeURL?account_id=` +
                    //   user?.stripe_id +
                    //   "&userId=" +
                    //   user?._id)
                    // }
                    >
                      Click here to verify your Details
                    </button>
                  )}
                </div>
              </Card>
            </Tab.Pane>
            <Tab.Pane eventKey="tabTwo">
              <Card className="card-gutter-md">
                <div className="card-row card-row-lg col-sep col-sep-lg justify-content-center p-5">
                  {notaryDetails === true ?
                    <h5 > Your Notary Profile is complete. <Icon name="check-circle"></Icon></h5>
                    :
                    <button
                      className="btn btn-primary w-100"
                      onClick={() => window.open('https://apps.e-signlive.com/a/account/notarycommission', '_blank')
                        // (window.open = `https://apps.e-signlive.com/a/account/notarycommission`)
                      }
                    >
                      Click here to view your Notary Details
                    </button>}

                  {/* <button
                    className="btn btn-primary w-100"
                    onClick={() => window.open('https://apps.e-signlive.com/a/account/notarycommission', '_blank')
                      // (window.open = `https://apps.e-signlive.com/a/account/notarycommission`)
                    }
                  >
                    Click here to view your Notary Details
                  </button> */}
                  {/* <div className="card-content col-sep w-50">
                    <Card.Body>
                      <div className="bio-block">
                        <h4 className="bio-block-title">Notary Details</h4>
                        <ul className="list-group list-group-borderless small">
                          <li className="list-group-item">
                            <span className="title fw-bold w-40 d-inline-block">
                              Notary Name :{" "}
                            </span>
                            <span className="text">Wesley </span>
                          </li>
                          <li className="list-group-item">
                            <span className="title fw-bold w-40 d-inline-block">
                              {" "}
                              Number :{" "}
                            </span>
                            <span className="text">Burland</span>
                          </li>
                          <li className="list-group-item">
                            <span className="title fw-bold w-40 d-inline-block">
                              Expiration date :{" "}
                            </span>
                            <span className="text">wesley@gmail.com</span>
                          </li>
                          <li className="list-group-item">
                            <span className="title fw-bold w-40 d-inline-block">
                              Jurisdiction :{" "}
                            </span>
                            <span className="text">Alaska</span>
                          </li>
                          <li className="list-group-item">
                            <span className="title fw-bold w-40 d-inline-block">
                              Country :{" "}
                            </span>
                            <span className="text">United State of India</span>
                          </li>
                          <li className="list-group-item">
                            <span className="title fw-bold w-40 d-inline-block">
                              Seal :{" "}
                            </span>
                            <span className="text">
                              <img
                                alt=""
                                src={
                                  "https://fastly.picsum.photos/id/237/200/300.jpg?hmac=TmmQSbShHz9CdQm0NkEjx1Dyh_Y984R9LpNrpvH2D_U"
                                }
                              />
                            </span>
                          </li>
                        </ul>
                      </div>
                    </Card.Body>
                  </div> */}
                </div>
              </Card>
            </Tab.Pane>
            <Tab.Pane eventKey="tabThree">
              <Card className="card-gutter-md">
                <PlanPricing />
              </Card>
            </Tab.Pane>
            {/* IF user.acctVerified is true else show "Please verify your account" */}

            <Tab.Pane eventKey="tabFour">
              {isStripeOnboardingComplete ? (
                <Card className="card-gutter-md">
                  <div className="card-row card-row-lg col-sep col-sep-lg">
                    <div className="card-content col-sep w-50">
                      <Card.Body>
                        <div className="bio-block">
                          <ul className="list-group list-group-borderless small">
                            <li className="list-group-item">
                              <span className="title fw-bold w-40 d-inline-block">
                                Balance :
                              </span>
                              <span className="text">
                                {user.amountToRedeem}{" "}
                              </span>
                            </li>
                          </ul>
                          <Button
                            onClick={() => {
                              // SHOW confirmation popup
                              Swal.fire({
                                title: "Are you sure?",
                                text: `You want to redeem ${user.amountToRedeem} $ from wallet? Note: 3% Merchant Fee would be deducted. Amount will be transferred to your bank account in 24 Hours.`,
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Yes, Redeem it!",
                                cancelButtonText: "No, cancel!",
                                reverseButtons: true,
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  // Call API to redeem amount from wallet
                                  redeemFromWallet();
                                } else if (
                                  result.dismiss === Swal.DismissReason.cancel
                                ) {
                                  Swal.fire(
                                    "Cancelled",
                                    "Your amount is safe :)",
                                    "error"
                                  );
                                }
                              });
                            }}
                            disabled={user.amountToRedeem == 0}
                            variant="primary"
                            size="sm"
                            className="mt-3"
                          >
                            Redeem
                          </Button>
                        </div>
                      </Card.Body>
                    </div>
                  </div>
                </Card>
              ) : (
                <div> Please verify your account to redeem amount.</div>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Block>
      </Tab.Container>
    </Layout >
  );
}

export default ViewProfile;
