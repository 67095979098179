import BaseApi from "..";

class StripeApi extends BaseApi{
    async create_subscription(data) {
        console.log("data", )
        return await this.API({
          url: "stripe/stripe_checkout/subscription",
          method: "post",
          data:data
        });
      };
    async create_invoice(data) {
        console.log("data", )
        return await this.API({
          url: "invoice/add",
          method: "post",
          data:data
        });
      };
    async getInvoice(params) {
        return await this.API({
          url: `invoice/list/?userId=${params}`,
          method: "get",
        });
      };
      
    async checkOnboardingStatus(data) {
        return await this.API({
          url: `/stripe/stripe_account_verified/status`,
          method: "post",
          data:data
        });
      }
    async getBankDetails(data) {
        return await this.API({
          url: `/stripe/get-bank-detail-from-stripe`,
          method: "post",
          data:data
        });
      }
}

export default StripeApi;