import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Row,
  Col,
  Spinner,
  Form,
} from 'react-bootstrap';

import Layout from '../../layout/default';
import DataTable from '../../components/DataTable/DataTable';
// import { list_tableColumns } from '../../components/DataTable/TableData';

import { Link, useNavigate } from 'react-router-dom';
import { Image, Icon, Media, MediaGroup, MediaText, CustomDropdownMenu, CustomDropdownToggle, OverlineTitle, ChartLegend, LinkList, LinkListItem } from '../../components';
import { API_URL } from '../../config';
import Swal from 'sweetalert2/src/sweetalert2';






  const UsersList = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  
  const [showItemPerPage, setShowItemPerPage] = useState(10);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalRows, setTotalRows] = useState(0);

 


  var sr_no = 0

  console.log('currentPage :>> ', currentPageNo);
 const list_tableColumns = [
  // {
  //   name: "Id",
  //   selector: (row) => row?.uid,
  //   sortable: true,
  // },
  {
    name: "Sr No",
    // selector: (row, index) => sr_no = index+1, 
    cell: (row, index) => (currentPageNo - 1) * showItemPerPage + index + 1,
    sortable: true,
  },
  {
    name: "Name",
    selector: (row) => `${row.firstname} ${row.lastname}`,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email
  },
  {
    name: "Subscription Status",
    selector: (row) => row.subscription_id,
    // cell: (row) => (
    //   <span >
    //     {row.subscription_id ? "Registered" : "Not Registered"}
    //   </span>
    // ),
    // cell: (row) => (
    //   <span className={`badge text-bg-${row.squarePaymentDone ? "success"
    //     : "warning"}`
    //   }>
    //     {row.squarePaymentDone ? "Active" : "Inactive"}
    //   </span>
    // ),
    cell: (row,index) => (
    <div className="ms-2">
                <Form.Check type="switch" id="flexSwitchChecked"
                  name='isActive'
                  //  onChange={(e) => handleChange(e, "check",index,row?._id)}
                  onChange={(e) => handleChange(e, "check", (currentPageNo - 1) * showItemPerPage + index, row?._id)}
                  checked={row.squarePaymentDone  ? true : false}

                />
              </div>)
  },
  {
    name: "Account Status",
    selector: (row) => <span className={`badge text-bg-${row.isActive ? "success"
    : "danger"}`
  }> {row.isActive ? "Active" : "Deactivated"}</span>
  },
  {
    name: "Bank Account Verified",
    selector: (row) => row?.bankDetailComplete,
    cell: (row) => (
      <span >
        {row?.bankDetailComplete?"Yes":"No"}
      </span>
    )
  },
   
  {
    name: "Notary Priviliges",
    selector: (row) => row?.hasNotaryPrivileges,
  cell: (row) => (
    <span >
      {row?.hasNotaryPrivileges?"Yes":"No"}
    </span>
  ),
  },
  {
    name: "Actions",
    // selector: (row) => row.subscription_id,
    cell: (row) => (
      <LinkList className="link-list-hover-bg-primary link-list-md" style={{ "display": "flex" }}>
        <span >
          {/* <Link to={`/list/${row.uid}`}>
            <Icon name="eye" className="text-success fs-3 pe-3"></Icon>
          </Link> */}
          <Link to={`/edit-list/${row.uid}`}>
            <Icon name="pen" className="text-success   fs-3 pe-3"></Icon>
          </Link>
          <Link to="/list">
            <a
              href="/list"
              onClick={async () => {
                Swal.fire({
                  title: 'Are you sure?',
                  text: "You won't be able to revert this!",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Yes, delete it!'
                }).then(async (result) => {
                  if(result.value) {
                      const res = await axios.delete(`${API_URL}/client/list/${row.uid}`);
                     console.log(res);
                     window.location.reload();
                      Swal.fire('Deleted', 'You successfully deleted this notary', 'success')
                    } else {
                      Swal.fire('Cancelled', 'Your notary is still intact', 'info')
                    }
                })
              }}
            >
              <Icon name="trash" className="text-danger fs-3 pe-3"></Icon>
            </a>
          </Link>
        </span>
      </LinkList>
    ),
  },
  // {
  //   name: "Transaction status",
  //   selector: (row) => row.package_status,
  //   cell: (row) => (
  //     <span >
  //       {row.package_status ? "Completed" : "In Progress"}
  //     </span>
  //   ),
  // },
  // {
  //   name: "Invoice",
  //   selector: (row) => row.package_status,
  //   cell: (row) => (
  //     <span >
  //       {row.package_status ? "Completed" : "In Progress"}
  //     </span>
  //   ),
  // },
];


const navigate =  useNavigate();



  async function getList() {
    try {
      // const back_end = `${process.env.REACT_APP_BACKEND}/client/list`
      // console.log(back_end)
      const response = await axios.get(`${API_URL}/client/users-list`);
      setList(response.data);
      setTotalRows(response?.data?.length)
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching list:', error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getList();
  }, []);

  const handleChange = async(e, type, index, id) => {
    console.log("inside handler", index);
  
    // e.preventDefault();
    if (type != undefined && type == "check") {
      console.log("checkedddddddd :>> ",id,e.target.checked);
     let checkedValue=e.target.checked

    console.log('edited')
    Swal.fire({
      title: 'Update Subscription Manually?',
     
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Update it!'
    }).then(async(result) => {
      console.log('result :>> ', result);
      if(result.value) {
        try {
     
        // await axios.put(`${API_URL}/client/update-subscription-status/${id}`,{ status: e.target.checked})
        await axios.put(`${API_URL}/client/update-subscription-status/${id}`,{ status: checkedValue})
        .then((res)=>{
          console.log('res :>> ', res);
          if(res?.data?.message){
            Swal.fire('Modified', 'You successfully Updated subscription ', 'success')
            getList()
            navigate('/list')
          }else{
      
            Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res?.message,
          })
      
          }
      
      
        })
        } catch (error) {
          console.error('Error updating client data:', error);
        }
       
        } else {
          Swal.fire('Cancelled', 'No changes made to subscription status', 'info')
        }
    })
  }
}



  return (  
    <Layout title="List of Notaries" content="container">
      <Row>
        <Col md="12">
          {loading ? (
            <Spinner animation="border" variant="secondary" />
            ) : (
            <>
             <div className="data-table-top">
        <h2>Users</h2>
        {/* <div className="data-table-search">
                <input 
                    className="form-control" 
                    placeholder="Search by name" 
                    type="text" 
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </div> */}
        <div className="data-table-action-wrap">
          {/* {actions && <Export data={data} />} */}
        </div>
        <div className="data-table-select">
          <select
            className="form-select"
            onChange={(e) => setShowItemPerPage(e.target.value)}
            value={showItemPerPage}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
          </select>
          <span className="text">Per page</span>
        </div>
      </div>
            <DataTable
            Title="Notaries"
              tableClassName="data-table-head-light table-responsive"
              data={list}
              columns={list_tableColumns}  
              showItemPerPage={showItemPerPage}
              setShowItemPerPage={setShowItemPerPage}
              setCurrentPageNo={(data)=>setCurrentPageNo(data)}
              // setCurrentPageNo={setCurrentPageNo}
              totalRows={totalRows}
              />
              </>
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default UsersList;