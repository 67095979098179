import React, { useEffect } from "react";
import classNames from "classnames";
import { Icon } from "../../../components";
// import getParents from '../../../utilities/getParents';
import slideUp from "../../../utilities/slideUp";
import slideDown from "../../../utilities/slideDown";
import getParents from "../../../utilities/getParents";

import { NavLink, Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { selectUser, setUser } from "../../../redux/global/reducer";
import { useSelector } from "react-redux";

function MenuHeading({ className, text, ...props }) {
  const compClass = classNames({
    "nk-menu-heading": true,
    [className]: className,
  });
  return (
    <li className={compClass}>
      <h6 className="overline-title">{text || props.children}</h6>
    </li>
  );
}

function MenuItemTemplate({ text, icon }) {
  return (
    <>
      {icon && (
        <span className="nk-menu-icon">
          <em className={`icon ni ni-${icon}`}></em>
        </span>
      )}
      {text && <span className="nk-menu-text">{text}</span>}
    </>
  );
}

function MenuItemLink({ text, icon, sub, to, blank, onClick }) {
  return (
    <>
      {!blank && !sub && (
        <NavLink className="nk-menu-link" to={to}>
          <MenuItemTemplate icon={icon} text={text} />
        </NavLink>
      )}
      {blank && (
        <Link className="nk-menu-link" to={to} target="_blank">
          <MenuItemTemplate icon={icon} text={text} />
        </Link>
      )}
      {sub && (
        <a
          className="nk-menu-link nk-menu-toggle"
          onClick={onClick}
          href="#expand"
        >
          <MenuItemTemplate icon={icon} text={text} />
        </a>
      )}
    </>
  );
}

function MenuItem({ sub, className, ...props }) {
  const compClass = classNames({
    "nk-menu-item": true,
    "has-sub": sub,
    [className]: className,
  });
  return <li className={compClass}>{props.children}</li>;
}

function MenuSub({ mega, className, ...props }) {
  const compClass = classNames({
    "nk-menu-sub": true,
    [className]: className,
  });
  return <ul className={compClass}>{props.children}</ul>;
}

function MenuList({ className, ...props }) {
  const compClass = classNames({
    "nk-menu": true,
    [className]: className,
  });
  return <ul className={compClass}>{props.children}</ul>;
}

function Menu() {
  // variables for Sidebar
  let menu = {
    classes: {
      main: "nk-menu",
      item: "nk-menu-item",
      link: "nk-menu-link",
      toggle: "nk-menu-toggle",
      sub: "nk-menu-sub",
      subparent: "has-sub",
      active: "active",
      current: "current-page",
    },
  };

  let currentLink = function (selector) {
    let elm = document.querySelectorAll(selector);
    elm.forEach(function (item) {
      var activeRouterLink = item.classList.contains("active");
      if (activeRouterLink) {
        let parents = getParents(
          item,
          `.${menu.classes.main}`,
          menu.classes.item
        );
        parents.forEach((parentElemets) => {
          parentElemets.classList.add(
            menu.classes.active,
            menu.classes.current
          );
          let subItem = parentElemets.querySelector(`.${menu.classes.sub}`);
          subItem !== null && (subItem.style.display = "block");
        });
      } else {
        item.parentElement.classList.remove(
          menu.classes.active,
          menu.classes.current
        );
      }
    });
  };

  // dropdown toggle
  let dropdownToggle = function (elm) {
    let parent = elm.parentElement;
    let nextelm = elm.nextElementSibling;
    let speed =
      nextelm.children.length > 5 ? 400 + nextelm.children.length * 10 : 400;
    if (!parent.classList.contains(menu.classes.active)) {
      parent.classList.add(menu.classes.active);
      slideDown(nextelm, speed);
    } else {
      parent.classList.remove(menu.classes.active);
      slideUp(nextelm, speed);
    }
  };

  // dropdown close siblings
  let closeSiblings = function (elm) {
    let parent = elm.parentElement;
    let siblings = parent.parentElement.children;
    Array.from(siblings).forEach((item) => {
      if (item !== parent) {
        item.classList.remove(menu.classes.active);
        if (item.classList.contains(menu.classes.subparent)) {
          let subitem = item.querySelectorAll(`.${menu.classes.sub}`);
          subitem.forEach((child) => {
            child.parentElement.classList.remove(menu.classes.active);
            slideUp(child, 400);
          });
        }
      }
    });
  };

  let menuToggle = function (e) {
    e.preventDefault();
    let item = e.target.closest(`.${menu.classes.toggle}`);
    dropdownToggle(item);
    closeSiblings(item);
  };

  useEffect(() => {
    currentLink(`.${menu.classes.link}`);
    // eslint-disable-next-line
  }, [null]);
  const currentuser = useSelector(selectUser)
  let dispatch = useDispatch();
  let navigate = useNavigate();
  return (
    <>
      <div className="d-flex flex-column justify-content-between">
        <MenuList>
          {/* <MenuItem>
            <MenuItemLink icon="list-check" text="Dashboard" to="/home" />
          </MenuItem>
          <MenuItem>
            <MenuItemLink icon="dashboard" text="Invoice" to="/invoice-list" />
          </MenuItem> */}
          <MenuItem>
            <MenuItemLink icon="list-check" text="Notaries" to="/list" />
          </MenuItem>

          <MenuItem>
            <MenuItemLink icon="users" text="Users" to="/users-list" />
          </MenuItem>
          {/* <MenuItem>
            <MenuItemLink icon="activity-alt" text="Notarial Transactions" to="/transaction-list" />
          </MenuItem>
          <MenuItem>
            <MenuItemLink icon="money" text="Bank Transactions" to="/bank-transactions" />
          </MenuItem> */}
          {/* <hr></hr> */}
          {/* <MenuItem>
            <MenuItemLink icon="user-list" blank="_blank" text="Training" to="https://aontraining.as.me/" />
          </MenuItem>
          <MenuItem>
            <MenuItemLink icon="question" text="FAQ" to="/faq" />
          </MenuItem>
          <MenuItem>
            <MenuItemLink icon="help" blank="_blank" text="Support" to="https://www.ayininternationalinc.org/aon-portal-support" />
          </MenuItem> */}
          {/* <MenuItem>
        <MenuItemLink icon="wallet" text="Wallets" to="/wallet-list" />
      </MenuItem>
      <MenuItem>
        <MenuItemLink icon="link" text="Tracking URL Generator" to="/tracking-url-generator" />
      </MenuItem> */}

          {/* <MenuItem>
        <MenuItemLink icon="copy" text="Tracking Scripts" to="/tracking-scripts" />
      </MenuItem> */}

          {/* <MenuItem>
          <MenuItemLink icon="list-check" text="Domains" to="/add-domain" />
        </MenuItem> */}
          {/* <MenuItem>
          <MenuItemLink icon="help" text="Support" to="/support" />
        </MenuItem>
        <MenuItem>
          <MenuItemLink icon="setting" text="Settings" to="/add-domain" />
        </MenuItem>
        <MenuItem>
          <MenuItemLink icon="layout-alt" text="Upcoming Features" to="/upcoming-features" />
        </MenuItem> */}


          {/* <div className="btn btn-primary p-0 mx-5 mt-5"
          onClick={() => {
            localStorage.removeItem("user");
            localStorage.removeItem("userDetail");
            window.location.reload();
          }}
        >

          <MenuItemLink icon="power" text="Logout" />
        </div> */}
        </MenuList>
        <div className="btn btn-primary align-self-center position-absolute bottom-0 mb-3"
          onClick={() => { dispatch(setUser(null)); navigate("/auth-login"); localStorage.removeItem('user_AYIN'); }}
        >
          <span onClick={() => { dispatch(setUser(null)); navigate("/auth-login"); localStorage.removeItem('user_AYIN'); }} className="d-flex align-items-center gap-2"><Icon name="user-alt-fill"></Icon> LOGOUT</span>
        </div>
      </div>
    </>
  );
}

export default Menu;